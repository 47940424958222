// src/pages/services/MobileApplication.js
import React from 'react';
import ServicePage from './ServicePage';
import { PhoneIphoneRounded, BuildRounded, ScreenshotMonitorRounded, Speed, Security, TrendingUp } from '@mui/icons-material';

function MobileApplication() {
  const features = [
    { 
      title: 'Native App Development', 
      description: 'High-performance apps tailored for iOS and Android platforms.',
      icon: <PhoneIphoneRounded />
    },
    { 
      title: 'Cross-Platform Solutions', 
      description: 'Efficient development for multiple platforms using technologies like React Native.',
      icon: <BuildRounded />
    },
    { 
      title: 'Mobile UI/UX Design', 
      description: 'Intuitive and engaging user interfaces for the best mobile experience.',
      icon: <ScreenshotMonitorRounded />
    },
  ];

  const benefits = [
    { title: 'Increased User Engagement', description: 'Provide a seamless, personalized experience for your users.', icon: <TrendingUp /> },
    { title: 'Improved Performance', description: 'Deliver fast, responsive applications optimized for mobile devices.', icon: <Speed /> },
    { title: 'Enhanced Security', description: 'Implement robust security measures to protect user data.', icon: <Security /> },
  ];

  const useCases = [
    'E-commerce and mobile shopping apps',
    'Social networking and communication platforms',
    'Health and fitness tracking applications',
    'Mobile banking and fintech solutions',
    'Educational and e-learning apps',
  ];

  const content = `
    <h2>Empower Your Business with Cutting-Edge Mobile Applications</h2>
    <p>At ARQNXS, we specialize in developing high-quality, innovative mobile applications that help businesses connect with their customers, streamline operations, and drive growth. Our team of expert mobile developers combines technical expertise with creative design to deliver exceptional mobile experiences.</p>
    
    <h3>Our Mobile App Development Process</h3>
    <ol>
      <li><strong>Discovery and Planning:</strong> We work closely with you to understand your goals and target audience.</li>
      <li><strong>UI/UX Design:</strong> Our designers create intuitive, visually appealing interfaces tailored to your brand.</li>
      <li><strong>Development:</strong> We build your app using the latest technologies and best practices.</li>
      <li><strong>Testing and Quality Assurance:</strong> We rigorously test your app to ensure performance and reliability.</li>
      <li><strong>Deployment:</strong> We handle the app store submission process and ensure a smooth launch.</li>
      <li><strong>Maintenance and Support:</strong> We provide ongoing support and updates to keep your app running smoothly.</li>
    </ol>

    <h3>Technologies We Use</h3>
    <ul>
      <li>Swift and SwiftUI for iOS development</li>
      <li>Kotlin and Jetpack Compose for Android development</li>
      <li>React Native and Flutter for cross-platform development</li>
      <li>Firebase for backend services and analytics</li>
      <li>ARKit and ARCore for augmented reality features</li>
    </ul>
  `;

  return (
    <ServicePage
      title="Mobile Application Development"
      description="Innovative mobile solutions to keep your business at your customers' fingertips."
      icon={<PhoneIphoneRounded />}
      features={features}
      benefits={benefits}
      useCases={useCases}
      content={content}
      callToAction="Ready to bring your mobile app idea to life? Let's start building together."
    />
  );
}

export default MobileApplication;