import React from 'react';
import IndustryTemplate from './IndustryTemplate';
import { Container, Typography, Box, Chip } from '@mui/material';

function FinTech() {
  const title = "FinTech & Banking Solutions";
  const description = "ARQNXS delivers cutting-edge AI and ML solutions tailored for the FinTech and Banking sector. Our innovative approaches help financial institutions streamline operations, enhance security, and provide superior customer experiences.";
  const solutions = [
    { 
      title: "AI-Driven Risk Assessment", 
      description: "Leverage machine learning algorithms to accurately assess and mitigate financial risks.",
      useCases: [
        "Credit scoring and loan approval automation",
        "Fraud detection and prevention",
        "Market risk analysis and forecasting"
      ],
      technologies: ["Machine Learning", "Natural Language Processing", "Big Data Analytics"]
    },
    { 
      title: "Blockchain Integration", 
      description: "Implement secure and transparent blockchain solutions for various financial applications.",
      useCases: [
        "Cross-border payments and remittances",
        "Digital asset management",
        "Smart contracts for financial instruments"
      ],
      technologies: ["Blockchain", "Smart Contracts", "Cryptography"]
    },
    { 
      title: "Automated Trading Systems", 
      description: "Develop high-frequency trading systems powered by advanced AI algorithms.",
      useCases: [
        "Algorithmic trading strategies",
        "Real-time market analysis",
        "Portfolio optimization"
      ],
      technologies: ["Machine Learning", "Time Series Analysis", "High-Performance Computing"]
    },
  ];

  const benefits = [
    "Enhanced risk management",
    "Improved operational efficiency",
    "Increased security and fraud prevention",
    "Better customer experiences",
    "Faster and more accurate decision-making"
  ];

  return (
    <IndustryTemplate title={title} description={description} solutions={solutions}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>Key Benefits</Typography>
        <Box display="flex" flexWrap="wrap" gap={1} mb={4}>
          {benefits.map((benefit, index) => (
            <Chip key={index} label={benefit} color="primary" />
          ))}
        </Box>
        <Typography variant="h4" gutterBottom>Our Approach</Typography>
        <Typography variant="body1" paragraph>
          At ARQNXS, we understand the unique challenges and opportunities in the FinTech and Banking sector. Our team of financial experts and AI specialists work together to develop tailored solutions that address specific pain points and drive innovation. We prioritize security, compliance, and scalability in all our FinTech solutions, ensuring that our clients can confidently navigate the rapidly evolving financial landscape.
        </Typography>
      </Container>
    </IndustryTemplate>
  );
}

export default FinTech;