import React from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Paper, 
  Avatar, 
  IconButton 
} from '@mui/material';
import { motion } from 'framer-motion';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import profilepic from "../pages/icons/profilepic.jpg"
import profilepicpeter from "../pages/icons/profilepicpeter.jpg"

const teamMembers = [
  { 
    name: 'Nigel van der Laan', 
    role: 'Founder/Head of AI', 
    image: profilepic,
    linkedin: 'https://www.linkedin.com/in/nigelvanderlaan/'
  },
  { 
    name: 'Peter Meijer', 
    role: 'Social Media Strategist', 
    image: profilepicpeter,
    linkedin: 'https://www.linkedin.com/in/inspiratieloos/'
  },
];

const TeamSection = () => {
  return (
    <Box my={6}>
      <Typography variant="h4" gutterBottom className="section-subtitle">Our Leadership</Typography>
      <Grid container spacing={4}>
        {teamMembers.map((member, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Paper elevation={3} className="team-member-card" sx={{ padding: 2, textAlign: 'center' }}>
                <Avatar 
                  src={member.image} 
                  alt={member.name} 
                  className="team-member-avatar"
                  sx={{ width: 120, height: 120, margin: '0 auto 16px' }}
                />
                <Typography variant="h6">{member.name}</Typography>
                <Typography variant="subtitle2" gutterBottom>{member.role}</Typography>
                <IconButton 
                  color="primary" 
                  component="a" 
                  href={member.linkedin} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  aria-label={`${member.name}'s LinkedIn profile`}
                >
                  <LinkedInIcon />
                </IconButton>
              </Paper>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TeamSection;