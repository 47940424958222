import React from 'react';
import { Container, Typography, Grid, Paper, Box, Button} from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import Lottie from 'lottie-react';
import { motion } from 'framer-motion';
import './About.css';
import officespace from "../pages/icons/officespace.jpg";
import { Link } from 'react-router-dom';
import TeamSection from './TeamSection';

import countries from "../pages/icons/countries.json";
import growth from "../pages/icons/growth1.json";
import divisions from "../pages/icons/divisions.json";
import jointventures from "../pages/icons/jointventures.json";


// Import 
import founded from "../pages/icons/founded.jpg";
import aiml from "../pages/icons/aiml.jpg";
import fintech from "../pages/icons/fintechexpansion.jpg";
import expandtech from "../pages/icons/expandtech.jpg";


function About() {
  const stats = [
    { value: '5', label: 'Countries', icon: () => <Lottie animationData={countries} /> },
    { value: '2', label: 'Joint Ventures', icon: () => <Lottie animationData={jointventures} /> },
    { value: '112%', label: 'Annual Growth', icon: () => <Lottie animationData={growth} /> },
    { value: '3', label: 'Divisions', icon: () => <Lottie animationData={divisions} /> }
  ];

  const milestones = [
    { 
      year: '2017', 
      event: 'ARQNXS Founded',
      description: 'Our journey began with a vision to revolutionize the healthcare industry through innovative solutions.',
      image: founded
    },
    { 
      year: '2018', 
      event: 'Expanded Healthcare with Technical division',
      description: 'We expanded our expertise into the healthcare sector, providing community based technology and government focused medical aid.',
      image: expandtech
    },
    
    { 
      year: '2022', 
      event: 'Pioneered Fintech Solutions',
      description: 'We ventured into the world of financial technology, aiming to transform traditional financial services translated from biophychological scientific implementation.',
      image: fintech
    },
    { 
      year: '2023', 
      event: 'Launched AI/ML division',
      description: 'Recognizing the power of AI, we established a dedicated division to harness its potential across industries.',
      image: aiml
    }
  ];



  return (
    <div className="about">
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h2" gutterBottom className="section-title">
            About ARQNXS
          </Typography>
        </motion.div>
        
        <Grid container spacing={4} alignItems="center" className="about-intro">
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Typography variant="body1" paragraph className="about-description">
                ARQNXS is a leading technology company specializing in AI, FinTech, and Strategic Integrations. Founded in 2017, we've rapidly grown to become a key player in the software development industry, driving innovation and delivering cutting-edge solutions to our global clientele.
              </Typography>
              <Typography variant="body1" paragraph className="about-description">
                Our mission is to empower businesses with transformative technology solutions that drive growth, efficiency, and competitive advantage in the digital age.
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <img src={officespace} alt="ARQNXS Office" className="office-image" />
            </motion.div>
          </Grid>
        </Grid>

        <Box my={6}>
          <Typography variant="h4" gutterBottom className="section-subtitle">Our Impact</Typography>
          <Grid container spacing={4} className="stats-container">
            {stats.map((stat, index) => (
              <Grid item xs={6} md={3} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Paper elevation={3} className="stat-card">
                    <Box className="stat-content">
                      {stat.icon()} {/* Call the icon function here */}
                      <Typography variant="h3" className="stat-value">{stat.value}</Typography>
                      <Typography variant="subtitle1" className="stat-label">{stat.label}</Typography>
                    </Box>
                  </Paper>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box my={6}>
          <Typography variant="h4" gutterBottom className="section-subtitle">Our Journey</Typography>
          <Timeline position="alternate">
            {milestones.map((milestone, index) => (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <Paper elevation={3} className="milestone-card">
                      <img src={milestone.image} alt={milestone.event} className="milestone-image" />
                      <Typography variant="h6" className="milestone-year">{milestone.year}</Typography>
                      <Typography variant="h5" className="milestone-event">{milestone.event}</Typography>
                      <Typography variant="body2" className="milestone-description">{milestone.description}</Typography>
                    </Paper>
                  </motion.div>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Box>

       

         <TeamSection />


        <Box my={6} textAlign="center">
          <Typography variant="h4" gutterBottom className="section-subtitle">Join Our Journey</Typography>
          <Typography variant="body1" paragraph>
            We're always looking for talented individuals to join our team and help shape the future of technology.
          </Typography>
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Button variant="contained" color="primary" component={Link} to="/contact" size="large">
              View Career Opportunities
            </Button>
          </motion.div>
        </Box>
      </Container>
    </div>
  );
}

export default About;