// src/pages/services/CloudSolutions.js
import React from 'react';
import ServicePage from './ServicePage';
import { CloudRounded, StorageRounded, SecurityRounded, Speed, TrendingUp, SaveAlt } from '@mui/icons-material';

function CloudSolutions() {
  const features = [
    { 
      title: 'Cloud Migration', 
      description: 'Seamlessly transition your existing systems to the cloud.',
      icon: <CloudRounded />
    },
    { 
      title: 'Scalable Infrastructure', 
      description: 'Build flexible, scalable solutions that grow with your business.',
      icon: <StorageRounded />
    },
    { 
      title: 'Cloud Security', 
      description: 'Implement robust security measures to protect your cloud-based assets.',
      icon: <SecurityRounded />
    },
  ];

  const benefits = [
    { title: 'Cost Efficiency', description: 'Reduce infrastructure costs and pay only for what you use.', icon: <SaveAlt /> },
    { title: 'Scalability', description: 'Easily scale resources up or down based on demand.', icon: <TrendingUp /> },
    { title: 'Improved Performance', description: 'Leverage high-performance computing resources for faster operations.', icon: <Speed /> },
  ];

  const useCases = [
    'Hybrid and multi-cloud deployments',
    'Disaster recovery and business continuity',
    'Big data analytics and processing',
    'Microservices and containerized applications',
    'Serverless computing architectures',
  ];

  const content = `
    <h2>Empowering Your Business with Cloud Solutions</h2>
    <p>At ARQNXS, we help businesses harness the full potential of cloud computing to drive innovation, improve efficiency, and reduce costs. Our cloud solutions are designed to provide the flexibility, scalability, and security your business needs to thrive in the digital age.</p>
    
    <h3>Our Cloud Services</h3>
    <p>We offer a comprehensive suite of cloud services, including:</p>
    <ul>
      <li><strong>Cloud Strategy and Consulting:</strong> We help you develop a tailored cloud adoption strategy aligned with your business goals.</li>
      <li><strong>Cloud Migration:</strong> Our experts ensure a smooth transition of your applications and data to the cloud.</li>
      <li><strong>Cloud-Native Development:</strong> We build modern, scalable applications optimized for cloud environments.</li>
      <li><strong>Cloud Infrastructure Management:</strong> We design, implement, and manage robust cloud infrastructures.</li>
      <li><strong>Cloud Security and Compliance:</strong> We implement best-in-class security measures to protect your cloud assets and ensure regulatory compliance.</li>
    </ul>

    <h3>Cloud Platforms We Work With</h3>
    <p>Our cloud solutions leverage leading cloud platforms and technologies, including:</p>
    <ul>
      <li>Amazon Web Services (AWS)</li>
      <li>Microsoft Azure</li>
      <li>Google Cloud Platform (GCP)</li>
      <li>IBM Cloud</li>
      <li>Oracle Cloud</li>
    </ul>

    <h3>Our Approach</h3>
    <p>We follow a proven methodology for cloud implementation:</p>
    <ol>
      <li><strong>Assessment:</strong> We evaluate your current IT infrastructure and business needs.</li>
      <li><strong>Strategy:</strong> We develop a comprehensive cloud adoption strategy.</li>
      <li><strong>Design:</strong> Our experts design a cloud architecture tailored to your requirements.</li>
      <li><strong>Migration:</strong> We execute a seamless migration of your systems and data to the cloud.</li>
      <li><strong>Optimization:</strong> We continuously monitor and optimize your cloud environment for performance and cost-efficiency.</li>
    </ol>
  `;

  return (
    <ServicePage
      title="Cloud Solutions"
      description="Harness the power of the cloud to enhance flexibility, scalability, and efficiency."
      icon={<CloudRounded />}
      features={features}
      benefits={benefits}
      useCases={useCases}
      content={content}
      callToAction="Ready to move to the cloud? Let's start your digital transformation journey."
    />
  );
}

export default CloudSolutions;