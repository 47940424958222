import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import ServiceCalculator from './ServiceCalculator';

const ServiceCalculatorPage = () => {
  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h3" component="h1" gutterBottom align="center">
          Cost Reduction Calculator
        </Typography>
        <ServiceCalculator />
        <Box mt={4} textAlign="center">
          <Button 
            variant="contained" 
            color="secondary" 
            component={Link} 
            to="/contact" 
            size="large"
          >
            Start Your Transformation
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ServiceCalculatorPage;