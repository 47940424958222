// src/components/LatestNewsTemplate.js
import React from 'react';
import { Container, Typography, Box, Chip, Divider } from '@mui/material';
import { CalendarToday, Person } from '@mui/icons-material';
import './LatestNewsTemplate.css';

const LatestNewsTemplate = ({ title, date, author, content, tags, image }) => {
  return (
    <article className="news-article">
      <Container maxWidth="md">
        <Typography variant="h1" className="article-title">
          {title}
        </Typography>
        <Box className="article-meta">
          <Typography variant="subtitle1" className="article-date">
            <CalendarToday fontSize="small" /> {date}
          </Typography>
          {author && (
            <Typography variant="subtitle1" className="article-author">
              <Person fontSize="small" /> {author}
            </Typography>
          )}
        </Box>
        {image && (
          <Box className="article-image">
            <img src={image} alt={title} />
          </Box>
        )}
        <Divider className="article-divider" />
        <div 
          className="article-content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {tags && tags.length > 0 && (
          <Box className="article-tags">
            {tags.map((tag, index) => (
              <Chip key={index} label={tag} variant="outlined" />
            ))}
          </Box>
        )}
      </Container>
    </article>
  );
};

export default LatestNewsTemplate;