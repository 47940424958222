import React, { useState } from 'react';
import { Container, Typography, Grid, Card, CardContent, Button, Box } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import './CaseStudies.css';

const CaseStudyCard = ({ study, index }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <Card className="case-study-card">
        <CardContent>
          <Typography variant="h6" gutterBottom className="case-study-title">
            {study.title}
          </Typography>
          <Typography variant="subtitle2" gutterBottom className="case-study-client">
            Client: {study.client}
          </Typography>
          <AnimatePresence>
            {isExpanded && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Typography variant="body2" className="case-study-result">
                  <span className="result-label">Solution:</span> {study.result}
                </Typography>
              </motion.div>
            )}
          </AnimatePresence>
          <Box className="case-study-actions">
            <Button
              startIcon={isExpanded ? <ExpandLess /> : <ExpandMore />}
              onClick={() => setIsExpanded(!isExpanded)}
              className="case-study-button"
            >
              {isExpanded ? 'Show Less' : 'Show More'}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </motion.div>
  );
};

const CaseStudies = () => {
    const caseStudies = [
        {
          title: "Custom AI Model for Predictive Maintenance",
          client: "Manufacturing Company",
          result: "Developing a custom AI model to reduce equipment downtime by 30% through predictive maintenance and real-time monitoring."
        },
        {
          title: "Machine Learning Integration for Sales Forecasting",
          client: "Retail Chain",
          result: "Implementing a machine learning solution to improve sales forecasting accuracy by 20%, optimizing inventory management and reducing costs."
        },
        {
          title: "Natural Language Processing for Customer Support",
          client: "E-commerce Platform",
          result: "Developing an NLP system to automate 70% of customer support queries, enhancing user experience and reducing operational costs."
        },
        {
          title: "Computer Vision for Quality Control",
          client: "Automotive Manufacturer",
          result: "Implementing a computer vision solution to increase quality control efficiency by 40%, ensuring higher product standards and reducing defects."
        },
        {
          title: "Blockchain Implementation for Secure Transactions",
          client: "Financial Services Firm",
          result: "Deploying a blockchain solution to ensure secure, transparent, and tamper-proof transactions, enhancing trust and compliance."
        },
        {
          title: "Digital Banking Platform Development",
          client: "Online Bank",
          result: "Creating a comprehensive digital banking platform to attract 50,000 new users within six months, providing seamless and secure banking services."
        },
        {
          title: "AI-Driven Fraud Detection System",
          client: "Payment Gateway Provider",
          result: "Developing an AI-driven fraud detection system to reduce fraudulent transactions by 25%, enhancing security and customer trust."
        },
        {
          title: "Automated Trading System",
          client: "Hedge Fund",
          result: "Engineering an automated trading system to increase trading efficiency by 35% and generate higher returns through intelligent algorithms."
        },
        {
          title: "Deep Learning Research for Healthcare",
          client: "Medical Research Institute",
          result: "Conducting deep learning research to lead to breakthroughs in early disease detection, improving patient outcomes and advancing medical knowledge."
        },
        {
          title: "Sustainable Tech Solutions for Energy Management",
          client: "Renewable Energy Company",
          result: "Developing sustainable tech solutions to optimize energy management, reducing carbon footprint by 20% and promoting eco-friendly practices."
        },
        {
          title: "Next-Gen IoT Ecosystem for Smart Cities",
          client: "Urban Development Agency",
          result: "Implementing a next-gen IoT ecosystem to improve urban infrastructure efficiency, enhancing the quality of life for residents."
        },
        {
          title: "Advanced Theoretical Exploration in Quantum Computing",
          client: "Tech Research Lab",
          result: "Leading advanced theoretical exploration in quantum computing, paving the way for future innovations and practical applications in various industries."
        }
      ];
      

  return (
    <section className="case-studies">
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h2" gutterBottom className="section-title">
            Case Studies
          </Typography>
          <Typography variant="h6" gutterBottom className="section-subtitle">
            Discover how we can help your business achieve remarkable results
          </Typography>
        </motion.div>
        <Grid container spacing={4} mt={0}>
          {caseStudies.map((study, index) => (
            <Grid item xs={12} md={4} key={index}>
              <CaseStudyCard study={study} index={index} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
};

export default CaseStudies;