import React from 'react';
import IndustryTemplate from './IndustryTemplate';
import { Container, Typography, Box, Chip } from '@mui/material';

function Transportation() {
  const title = "Transportation & Logistics Solutions";
  const description = "ARQNXS revolutionizes the transportation and logistics industry with AI-powered solutions that optimize routes, improve fleet management, and enhance supply chain efficiency.";
  const solutions = [
    { 
      title: "Intelligent Route Optimization", 
      description: "Utilize AI algorithms for real-time route optimization and traffic prediction.",
      useCases: [
        "Dynamic route planning for delivery fleets",
        "Real-time traffic prediction and avoidance",
        "Multi-modal transportation optimization"
      ],
      technologies: ["Machine Learning", "Graph Theory", "Real-time Data Processing"]
    },
    { 
      title: "Predictive Fleet Maintenance", 
      description: "Implement machine learning models to predict vehicle maintenance needs and optimize fleet performance.",
      useCases: [
        "Predictive maintenance scheduling",
        "Component failure prediction",
        "Fuel efficiency optimization"
      ],
      technologies: ["IoT Sensors", "Predictive Analytics", "Digital Twin Technology"]
    },
    { 
      title: "Supply Chain Visibility", 
      description: "Develop AI-enhanced systems for end-to-end supply chain visibility and management.",
      useCases: [
        "Real-time tracking of shipments and inventory",
        "Demand forecasting and inventory optimization",
        "Risk assessment and mitigation in supply chains"
      ],
      technologies: ["Blockchain", "IoT", "Machine Learning"]
    },
  ];

  const benefits = [
    "Reduced operational costs",
    "Improved on-time delivery rates",
    "Enhanced fleet utilization and efficiency",
    "Increased supply chain resilience",
    "Better environmental sustainability"
  ];

  return (
    <IndustryTemplate title={title} description={description} solutions={solutions}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>Key Benefits</Typography>
        <Box display="flex" flexWrap="wrap" gap={1} mb={4}>
          {benefits.map((benefit, index) => (
            <Chip key={index} label={benefit} color="primary" />
          ))}
        </Box>
        <Typography variant="h4" gutterBottom>Our Approach</Typography>
        <Typography variant="body1" paragraph>
          At ARQNXS, we understand the complex challenges facing the transportation and logistics industry. Our team of experts combines deep industry knowledge with cutting-edge AI and data analytics capabilities to deliver solutions that drive efficiency, reduce costs, and improve customer satisfaction. We work closely with our clients to develop and implement customized solutions that address their specific needs and help them stay ahead in a rapidly evolving industry.
        </Typography>
      </Container>
    </IndustryTemplate>
  );
}

export default Transportation;