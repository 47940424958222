import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Grid, Box, Paper, List, ListItem, ListItemIcon, ListItemText, Fade, Zoom, MenuItem } from '@mui/material';
import { Language, CheckCircleOutline, Send } from '@mui/icons-material';
import { motion } from 'framer-motion';
import Calendly from './Calendly'; 

const benefits = [
  "Cutting-edge technology expertise",
  "Tailored solutions for your business needs",
  "Experienced team of professionals",
  "Proven track record of successful projects",
  "Commitment to innovation and excellence"
];

const services = [
  "Web Development",
  "Mobile Application",
  "Data Analytical Reporting",
  "Cloud Solutions",
  "AI and Machine Learning",
  "Blockchain Development",
  "IoT Solutions",
  "Carreer"
];

function Contact() {
  const [formData, setFormData] = useState({ 
    name: '', 
    email: '', 
    companyName: '',
    companyEmail: '',
    phoneNumber: '',
    service: '',
    message: '' 
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  
    try {
      const response = await fetch('/.netlify/functions/send-message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
  
      if (response.ok) {
        setIsSubmitted(true);
      } else {
        console.error('Error submitting the form');
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  };
  

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography variant="h2" gutterBottom align="center" sx={{ mb: 6, fontWeight: 'bold', color: '#1976d2' }}>
        Get in Touch
      </Typography>

      <Grid item xs={12} sx={{ mb: 8 }}> {/* Added margin-top */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <Paper elevation={3} sx={{ p: 4, borderRadius: 4, bgcolor: '#f0f7ff' }}>
            <Typography variant="h4" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold', textAlign: 'center' }}>
              Book a Free Consultation
            </Typography>
            <Calendly />
          </Paper>
        </motion.div>
      </Grid>

        <Typography variant="h2" gutterBottom align="center" sx={{ mb: 6,  fontWeight: 'bold', color: '#1976d2' }}>
        Request a callback
      </Typography>


      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Paper elevation={3} sx={{ p: 4, height: '100%', borderRadius: 4, bgcolor: '#f8f9fa' }}>
              {!isSubmitted ? (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Your Name"
                        name="name"
                        variant="outlined"
                        required
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Your Email"
                        name="email"
                        variant="outlined"
                        type="email"
                        required
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Company Name"
                        name="companyName"
                        variant="outlined"
                        value={formData.companyName}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Company Email"
                        name="companyEmail"
                        variant="outlined"
                        type="email"
                        value={formData.companyEmail}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Phone Number"
                        name="phoneNumber"
                        variant="outlined"
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        select
                        label="Service Required"
                        name="service"
                        variant="outlined"
                        required
                        value={formData.service}
                        onChange={handleInputChange}
                      >
                        {services.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Message"
                        name="message"
                        variant="outlined"
                        multiline
                        rows={4}
                        required
                        value={formData.message}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        type="submit"
                        startIcon={<Send />}
                        sx={{ py: 1.5 }}
                      >
                        Send Message
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              ) : (
                <Fade in={isSubmitted}>
                  <Box textAlign="center" py={4}>
                    <Typography variant="h5" gutterBottom color="primary">
                      Thank you for your message!
                    </Typography>
                    <Typography variant="body1">
                      We'll get back to you as soon as possible.
                    </Typography>
                  </Box>
                </Fade>
              )}
            </Paper>
          </motion.div>
        </Grid>
        <Grid item xs={12} md={6}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <Paper elevation={3} sx={{ p: 4, height: '100%', borderRadius: 4, bgcolor: '#e3f2fd' }}>
              <Typography variant="h4" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                Why Choose ARQNXS?
              </Typography>
              <List>
                {benefits.map((benefit, index) => (
                  <Zoom in={true} style={{ transitionDelay: `${index * 100}ms` }} key={index}>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleOutline color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={benefit} primaryTypographyProps={{ fontWeight: 500 }} />
                    </ListItem>
                  </Zoom>
                ))}
              </List>
            </Paper>
          </motion.div>
        </Grid>
      </Grid>

   

      <Box sx={{ mt: 6, textAlign: 'center' }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
        <Paper elevation={3} sx={{ p: 4, borderRadius: 4, display: 'inline-block' }}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item>
                <Box display="flex" alignItems="center">
                  <Language color="primary" sx={{ mr: 1 }} />
                  <Typography variant="h6">www.arqnxs.com</Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>


        </motion.div>
      </Box>
    </Container>
  );
}

export default Contact;