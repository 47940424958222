// src/pages/services/IoTSolutions.js
import React from 'react';
import ServicePage from './ServicePage';
import { RouterRounded, DevicesRounded, LockRounded, Speed, TrendingUp, Visibility } from '@mui/icons-material';

function IoTSolutions() {
  const features = [
    { 
      title: 'IoT Device Integration', 
      description: 'Seamlessly connect and manage a wide range of IoT devices.',
      icon: <DevicesRounded />
    },
    { 
      title: 'IoT Data Analytics', 
      description: 'Extract valuable insights from your IoT data streams.',
      icon: <RouterRounded />
    },
    { 
      title: 'IoT Security', 
      description: 'Implement robust security measures to protect your IoT ecosystem.',
      icon: <LockRounded />
    },
  ];

  const benefits = [
    { title: 'Enhanced Efficiency', description: 'Optimize operations with real-time data and automation.', icon: <Speed /> },
    { title: 'Predictive Maintenance', description: 'Reduce downtime and maintenance costs with predictive insights.', icon: <TrendingUp /> },
    { title: 'Improved Decision Making', description: 'Make informed decisions based on real-time IoT data.', icon: <Visibility /> },
  ];

  const useCases = [
    'Smart manufacturing and industrial IoT',
    'Connected healthcare devices and remote patient monitoring',
    'Smart home and building automation',
    'Agriculture and precision farming',
    'Smart cities and urban infrastructure management',
  ];

  const content = `
    <h2>Revolutionize Your Business with IoT Solutions</h2>
    <p>At ARQNXS, we help businesses harness the power of the Internet of Things (IoT) to drive innovation, improve efficiency, and create new value propositions. Our IoT solutions connect your devices, systems, and data to provide unprecedented insights and control.</p>
    
    <h3>Our IoT Development Process</h3>
    <ol>
      <li><strong>IoT Strategy:</strong> We work with you to identify the right IoT use cases for your business.</li>
      <li><strong>Device Selection:</strong> We help choose or design the appropriate IoT devices and sensors.</li>
      <li><strong>Connectivity:</strong> We implement secure, reliable communication protocols for your IoT network.</li>
      <li><strong>Data Processing:</strong> We set up edge computing and cloud infrastructure to process IoT data efficiently.</li>
      <li><strong>Analytics and Visualization:</strong> We develop dashboards and analytics tools to derive insights from your IoT data.</li>
      <li><strong>Security:</strong> We implement end-to-end security measures to protect your IoT ecosystem.</li>
    </ol>

    <h3>Technologies We Use</h3>
    <ul>
      <li>MQTT and CoAP for IoT communication protocols</li>
      <li>AWS IoT, Azure IoT, and Google Cloud IoT for cloud-based IoT platforms</li>
      <li>TensorFlow Lite and Edge TPU for edge AI</li>
      <li>Node-RED for IoT application development</li>
      <li>InfluxDB and TimescaleDB for time-series data storage</li>
    </ul>
  `;

  return (
    <ServicePage
      title="IoT Solutions"
      description="Connect, monitor, and optimize your devices with cutting-edge Internet of Things solutions."
      icon={<RouterRounded />}
      features={features}
      benefits={benefits}
      useCases={useCases}
      content={content}
      callToAction="Ready to connect your world? Let's build your IoT solution together."
    />
  );
}

export default IoTSolutions;