import React from 'react';
import Slider from 'react-slick';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Import all logos
import reactLogo from './techstacklogos/react.png';
import nodeLogo from './techstacklogos/node.png';
import pythonLogo from './techstacklogos/python.png';
import tensorflowLogo from './techstacklogos/tensorflow.png';
import awsLogo from './techstacklogos/aws.png';
import solidityLogo from './techstacklogos/solidity.png';
import dockerLogo from './techstacklogos/docker.png';
import kubernetesLogo from './techstacklogos/kubernetes.png';
import angularLogo from './techstacklogos/angular.png';
import elasticsearchLogo from './techstacklogos/elasticsearch.png';
import expressLogo from './techstacklogos/expressjs.png';
import azureLogo from './techstacklogos/azure.png';
import googleCloudLogo from './techstacklogos/googlecloud.png';
import kafkaLogo from './techstacklogos/kafka.png';
import kerasLogo from './techstacklogos/keras.png';
import mongodbLogo from './techstacklogos/mongodb.png';
import openaiLogo from './techstacklogos/openai.png';
import pysparkLogo from './techstacklogos/pyspark.png';
import rustLogo from './techstacklogos/rust.png';
import pytorchLogo from './techstacklogos/pytorch.png';
import rLogo from './techstacklogos/r.png';

const techStack = [
  { name: "React", logo: reactLogo },
  { name: "Node.js", logo: nodeLogo },
  { name: "Python", logo: pythonLogo },
  { name: "TensorFlow", logo: tensorflowLogo },
  { name: "AWS", logo: awsLogo },
  { name: "Solidity", logo: solidityLogo },
  { name: "Docker", logo: dockerLogo },
  { name: "Kubernetes", logo: kubernetesLogo },
  { name: "Angular", logo: angularLogo },
  { name: "ElasticSearch Cloud", logo: elasticsearchLogo },
  { name: "Express JS", logo: expressLogo },
  { name: "Azure", logo: azureLogo },
  { name: "Google Cloud", logo: googleCloudLogo },
  { name: "Kafka", logo: kafkaLogo },
  { name: "Keras", logo: kerasLogo },
  { name: "MongoDB", logo: mongodbLogo },
  { name: "OpenAI", logo: openaiLogo },
  { name: "PySpark", logo: pysparkLogo },
  { name: "Rust", logo: rustLogo },
  { name: "PyTorch", logo: pytorchLogo },
  { name: "R Programming", logo: rLogo },
];

function TechStackCarousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <section className="tech-stack">
      <Container maxWidth="lg">
        <Typography variant="h2" gutterBottom className="section-title">
          Our Technology Stack
        </Typography>
        <Box 
          sx={{
            textAlign: 'center',
            '& .slick-slide img': {
              display: 'inline-block',
              maxWidth: '100px',
              margin: '0 auto',
            },
          }}
        >
          <Slider {...settings}>
            {techStack.map((tech, index) => (
              <div key={index}>
                <img src={tech.logo} alt={tech.name} />
              </div>
            ))}
          </Slider>
        </Box>
      </Container>
    </section>
  );
}

export default TechStackCarousel;