import React, { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { 
  Typography, 
  Grid, 
  Paper, 
  Box, 
  Card, 
  CardContent,
  Tab,
  Tabs,
  Tooltip,
  IconButton,
  Chip,
  Divider,
  useMediaQuery,
  useTheme,
  ButtonGroup,
  Button
} from '@mui/material';
import { LineChart, Line, XAxis, YAxis, Tooltip as RechartsTooltip, ResponsiveContainer, Legend } from 'recharts';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import SecurityIcon from '@mui/icons-material/Security';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CampaignIcon from '@mui/icons-material/Campaign';
import BoltIcon from '@mui/icons-material/Bolt';
import LinkIcon from '@mui/icons-material/Link';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import WebIcon from '@mui/icons-material/Web';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import BarChartIcon from '@mui/icons-material/BarChart';
import CloudIcon from '@mui/icons-material/Cloud';
import PsychologyIcon from '@mui/icons-material/Psychology';
import RouterIcon from '@mui/icons-material/Router';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import CalculatorSection from './CalculatorSection';
import arqnxslogo from '../assets/arqnxslogo.jpg';


const services = {
  'Industries': {
    'FinTech': { icon: <AccountBalanceIcon />, solutions: [
      'Develop and implement a custom risk assessment algorithm for a lending company',
      'Create a secure API integration between a payment gateway and an e-commerce platform',
      'Build a regulatory compliance reporting system for a bank'
    ]},
    'Healthcare': { icon: <LocalHospitalIcon />, solutions: [
      'Implement HL7 integration for patient data exchange between hospitals',
      'Develop a custom medical inventory management system for a clinic',
      'Create a HIPAA-compliant patient portal for a medical practice'
    ]},
    'Intelligence': { icon: <SecurityIcon />, solutions: [
      'Conduct a comprehensive security audit and penetration testing for a company',
      'Develop a custom intrusion detection system for an organization',
      'Implement multi-factor authentication across systems for a financial services firm'
    ]},
    'Retail': { icon: <StorefrontIcon />, solutions: [
      'Develop a custom inventory forecasting system for a retail chain',
      'Create a loyalty program management system for an e-commerce business',
      'Implement a returns management system integrated with existing ERP for a retailer'
    ]},
    'Advertisement': { icon: <CampaignIcon />, solutions: [
      'Develop a custom A/B testing platform for a marketing agency',
      'Create an automated report generation system for an advertising firm',
      'Build a social media sentiment analysis tool for a brand management company'
    ]},
    'Energy': { icon: <BoltIcon />, solutions: [
      'Develop an energy consumption forecasting model for a utility company',
      'Create a custom dashboard for monitoring renewable energy performance',
      'Implement a smart meter data analysis system for a power distributor'
    ]},
    'Blockchain': { icon: <LinkIcon />, solutions: [
      'Develop a proof-of-concept for a supply chain tracking system using blockchain',
      'Create a cryptocurrency wallet with trading functionalities',
      'Implement a smart contract system for a real estate tokenization project'
    ]},
    'Transportation': { icon: <DirectionsBusIcon />, solutions: [
      'Develop a route optimization system for a delivery company',
      'Create a fleet maintenance scheduling and tracking system for a trucking firm',
      'Build a real-time GPS tracking and analytics platform for a transportation company'
    ]},
  },
  'Services': {
    'Web Development': { icon: <WebIcon />, solutions: [
      'Design and develop a custom e-commerce website for a retailer',
      'Create a content management system for a publishing company',
      'Build a responsive web application for internal corporate use'
    ]},
    'Mobile Application': { icon: <PhoneAndroidIcon />, solutions: [
      'Develop a cross-platform mobile app for a food delivery service',
      'Create a custom field service management app for a company',
      'Build a mobile-based inventory management system for a business'
    ]},
    'Data Analytics': { icon: <BarChartIcon />, solutions: [
      'Develop a custom sales forecasting model for a manufacturing company',
      'Create a data visualization dashboard for marketing KPIs',
      'Implement a customer segmentation analysis system for a business'
    ]},
    'Cloud Solutions': { icon: <CloudIcon />, solutions: [
      'Migrate an on-premise system to a cloud-based solution',
      'Implement a hybrid cloud storage solution for a company',
      'Develop a cloud-based disaster recovery system for an organization'
    ]},
    'AI/ML': { icon: <PsychologyIcon />, solutions: [
      'Develop a machine learning model for predictive maintenance',
      'Create a natural language processing system for customer support',
      'Build an image recognition system for quality control'
    ]},
    'Blockchain': { icon: <LinkIcon />, solutions: [
      'Develop a blockchain-based voting system prototype',
      'Create a decentralized exchange for digital assets',
      'Implement a blockchain solution for tracking and verifying credentials'
    ]},
    'IoT Solutions': { icon: <RouterIcon />, solutions: [
      'Develop an IoT system for monitoring and controlling HVAC in buildings',
      'Create a smart inventory tracking system using RFID',
      'Build an IoT-based agricultural monitoring system'
    ]},
  }
};

// Initialize with valid values
const initialCategory = Object.keys(services)[0];
const initialService = Object.keys(services[initialCategory])[0];

const ServiceCalculator = () => {
  const [manualHours, setManualHours] = useState(100);
  const [hourlyRate, setHourlyRate] = useState(50);
  const [selectedServices, setSelectedServices] = useState([]);
  const [activeCategory, setActiveCategory] = useState(initialCategory);
  const [activeService, setActiveService] = useState(initialService);
  const [chartData, setChartData] = useState([]);
  const [implementationSize, setImplementationSize] = useState(160); // Default to small

  
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCategoryChange = (_, newValue) => {
      setActiveCategory(newValue);
      setActiveService(Object.keys(services[newValue])[0]);
    };

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: 'Service Calculator Report',
    });

    const handleImplementationSizeChange = (size) => {
      setImplementationSize(size);
    };
  
    const calculateHoursReduced = () => {
      const totalReduction = selectedServices.length * implementationSize;
      if (manualHours >= totalReduction) {
        return `${totalReduction}`;
      } else {
        return `${manualHours} - ${totalReduction}`;
      }
    };
  
    const getNumericHoursReduced = () => {
      return Math.min(manualHours, selectedServices.length * implementationSize);
    };
  
    const remainingHours = Math.max(0, manualHours - getNumericHoursReduced());
    const costSavings = (manualHours - remainingHours) * hourlyRate;

  useEffect(() => {
    const newChartData = Array.from({ length: 12 }, (_, i) => {
      const month = i + 1;
      const originalHours = manualHours * month;
      const reducedHours = remainingHours * month;
      const savings = costSavings * month;
      return {
        month: `Month ${month}`,
        'Original Hours': originalHours,
        'Remaining Hours': reducedHours,
        'Cost Savings ($)': savings
      };
    });
    setChartData(newChartData);
  }, [manualHours, remainingHours, costSavings]);

  const handleServiceToggle = (service) => {
    setSelectedServices(prev => 
      prev.includes(service)
        ? prev.filter(s => s !== service)
        : [...prev, service]
    );
  };

  const PrintLayout = React.forwardRef(({ manualHours, hourlyRate, selectedServices, chartData, calculateHoursReduced, remainingHours, costSavings }, ref) => (
    <Box ref={ref} sx={{ width: '100%', maxWidth: '800px', margin: 'auto', padding: 3, fontFamily: 'Arial, sans-serif' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <img src={arqnxslogo} alt="Company Logo" style={{ height: '50px', marginRight: '20px' }} />
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333' }}>
          Service Calculator Report
        </Typography>
      </Box>
      
      <Typography variant="h5" gutterBottom sx={{ color: '#666', marginBottom: '20px' }}>
        Discover Your Cost Reduction
      </Typography>
      
      <Grid container spacing={3} sx={{ marginBottom: '20px' }}>
        <Grid item xs={12} sm={6}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ color: '#444' }}>Current Setup</Typography>
              <Typography>Manual Labor Hours: {manualHours}</Typography>
              <Typography>Hourly Rate: £{hourlyRate.toFixed(2)}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ color: '#444' }}>Estimated Results</Typography>
              <Typography>Total Cost Before Reduction: £{(manualHours * hourlyRate).toFixed(2)}</Typography>
              <Typography>Estimated Hours Reduced: {calculateHoursReduced()}</Typography>
              <Typography>Remaining Hours: {remainingHours}</Typography>
              <Typography>
              Efficiency Improvement: {
                manualHours > 0
                  ? Math.min(((getNumericHoursReduced() / manualHours) * 100), 100).toFixed(2)
                  : '0.00'
              }%
            </Typography>
              <Typography>Monthly Savings: £{costSavings.toFixed(2)}</Typography>
              <Typography>Total Cost After Reduction: £{(remainingHours * hourlyRate).toFixed(2)}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      <Box my={3}>
        <Typography variant="h6" gutterBottom sx={{ color: '#444' }}>Selected Services</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {selectedServices.map((service) => (
            <Chip key={service} label={service} sx={{ margin: '2px', backgroundColor: '#e0e0e0', color: '#333' }} />
          ))}
        </Box>
      </Box>
  
      <Box mt={3}>
        <Typography variant="h6" gutterBottom sx={{ color: '#444' }}>Cost and Hours Projection</Typography>
        <Box sx={{ width: '100%', height: '282px' }}> 
          <LineChart 
            width={700} 
            height={300} 
            data={chartData}
            margin={{ top: 5, right: 30, left: 20, bottom: 25 }} 
          >
            <XAxis dataKey="month" />
            <YAxis yAxisId="left" />
            <YAxis yAxisId="right" orientation="right" />
            <YAxis 
              yAxisId="right" 
              orientation="right" 
              label={{ 
                value: 'Amount', 
                angle: 90, 
                position: 'insideRight',
                offset: 0,
                style: { textAnchor: 'middle' }
              }}
            />
            <Tooltip />
            <Legend 
              layout="horizontal" 
              verticalAlign="bottom" 
              align="center"
              wrapperStyle={{ 
                paddingTop: '5px',
                position: 'absolute',
                bottom: '-15px',
                left: '50%',
                transform: 'translateX(-50%)'
              }}
            />
            <Line yAxisId="left" type="monotone" dataKey="Original Hours" stroke="#8884d8" />
            <Line yAxisId="left" type="monotone" dataKey="Remaining Hours" stroke="#82ca9d" />
            <Line yAxisId="right" type="monotone" dataKey="Cost Savings ($)" stroke="#ffc658" />
          </LineChart>
        </Box>
      </Box>
  
      <Box mt={3}>
        <Typography variant="caption" sx={{ color: '#666', display: 'block', marginTop: '50px' }}>
          Disclaimer: This report is based on estimates and projections. Actual results may vary. Please consult with our team for a detailed analysis tailored to your specific needs.
        </Typography>
      </Box>
    </Box>
  ));

  return (
    <Paper elevation={3} sx={{ p: isMobile ? 2 : 3 }}>
    <Box ref={componentRef}>
      <Typography variant="h4" gutterBottom>Service Calculator Report</Typography>
      
      <Box my={2}>
        <Typography variant="h6" gutterBottom>Select Implementation Size</Typography>
        <ButtonGroup variant="contained" aria-label="implementation size">
          <Button 
            onClick={() => handleImplementationSizeChange(160)}
            color={implementationSize === 160 ? "primary" : "inherit"}
          >
            Small (160 hours)
          </Button>
          <Button 
            onClick={() => handleImplementationSizeChange(320)}
            color={implementationSize === 320 ? "primary" : "inherit"}
          >
            Medium (320 hours)
          </Button>
          <Button 
            onClick={() => handleImplementationSizeChange(640)}
            color={implementationSize === 640 ? "primary" : "inherit"}
          >
            Large (640 hours)
          </Button>
        </ButtonGroup>
      </Box>

      <CalculatorSection 
        manualHours={manualHours}
        setManualHours={setManualHours}
        hourlyRate={hourlyRate}
        setHourlyRate={setHourlyRate}
        calculateHoursReduced={calculateHoursReduced}
        getNumericHoursReduced={getNumericHoursReduced}
        remainingHours={remainingHours}
        costSavings={costSavings}
        implementationSize={implementationSize}
      />
      
      <Box my={3}>
        <Typography variant="h6" gutterBottom>Selected Services</Typography>
        {selectedServices.length > 0 ? (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {selectedServices.map((service) => (
              <Chip
                key={service}
                label={service}
                onDelete={() => handleServiceToggle(service)}
                color="primary"
                sx={{ margin: '2px' }}
              />
            ))}
          </Box>
        ) : (
          <Typography color="textSecondary">No services selected</Typography>
        )}
      </Box>

      <Divider sx={{ my: 3 }} />

      <Tabs 
        value={activeCategory} 
        onChange={handleCategoryChange} 
        sx={{ mb: 2 }}
        variant={isMobile ? "scrollable" : "standard"}
        scrollButtons="auto"
      >
        {Object.keys(services).map((category) => (
          <Tab key={category} label={category} value={category} />
        ))}
      </Tabs>

      <Tabs 
        value={activeService} 
        onChange={(_, newValue) => setActiveService(newValue)} 
        sx={{ mb: 2 }}
        variant={isMobile ? "scrollable" : "standard"}
        scrollButtons="auto"
      >
        {Object.keys(services[activeCategory]).map((service) => (
          <Tab 
            key={service} 
            label={isMobile ? service : undefined}
            icon={!isMobile ? services[activeCategory][service].icon : undefined}
            value={service} 
          />
        ))}
      </Tabs>

      <Grid container spacing={2}>
        {services[activeCategory][activeService].solutions.map((solution) => (
          <Grid item xs={12} sm={6} md={4} key={solution}>
            <Card 
              raised={selectedServices.includes(solution)}
              onClick={() => handleServiceToggle(solution)}
              sx={{ 
                cursor: 'pointer', 
                transition: 'all 0.3s',
                '&:hover': { transform: 'scale(1.05)' },
                height: '100%',
                bgcolor: selectedServices.includes(solution) ? 'action.selected' : 'background.paper'
              }}
            >
              <CardContent>
                <Typography variant="body1">{solution}</Typography>
                <Tooltip title={selectedServices.includes(solution) ? "Click to deselect" : "Click to select"}>
                  <IconButton size="small">
                    {selectedServices.includes(solution) ? <CloseIcon /> : services[activeCategory][activeService].icon}
                  </IconButton>
                </Tooltip>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box mt={3} height={isMobile ? 300 : 400}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={chartData}>
            <XAxis dataKey="month" />
            <YAxis yAxisId="left" />
            <YAxis yAxisId="right" orientation="right" />
            <YAxis 
              yAxisId="right" 
              orientation="right" 
              label={{ 
                value: 'Amount', 
                angle: 90, 
                position: 'insideRight',
                offset: 0,
                style: { textAnchor: 'middle' }
              }}
            />
            <RechartsTooltip />
            <Legend />
            <Line yAxisId="left" type="monotone" dataKey="Original Hours" stroke="#8884d8" />
            <Line yAxisId="left" type="monotone" dataKey="Remaining Hours" stroke="#82ca9d" />
            <Line yAxisId="right" type="monotone" dataKey="Cost Savings ($)" stroke="#ffc658" />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>

    <Box mt={3}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<PrintIcon />}
          onClick={handlePrint}
        >
          Print to PDF
        </Button>
      </Box>

      {/* Hidden print layout */}
      <Box sx={{ display: 'none' }}>
        <PrintLayout
          ref={componentRef}
          manualHours={manualHours}
          hourlyRate={hourlyRate}
          selectedServices={selectedServices}
          chartData={chartData}
          calculateHoursReduced={calculateHoursReduced}
          remainingHours={remainingHours}
          costSavings={costSavings}
        />
      </Box>
    </Paper>
  );
};

export default ServiceCalculator;