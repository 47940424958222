// src/pages/services/DataAnalytics.js
import React from 'react';
import ServicePage from './ServicePage';
import { BarChartRounded, TimelineRounded, TrendingUpRounded, Visibility, Speed, Psychology } from '@mui/icons-material';

function DataAnalytics() {
  const features = [
    { 
      title: 'Data Visualization', 
      description: 'Transform complex data into clear, actionable insights.',
      icon: <BarChartRounded />
    },
    { 
      title: 'Predictive Analytics', 
      description: 'Leverage machine learning to forecast trends and behaviors.',
      icon: <TrendingUpRounded />
    },
    { 
      title: 'Real-time Reporting', 
      description: 'Access up-to-the-minute data for informed decision-making.',
      icon: <TimelineRounded />
    },
  ];

  const benefits = [
    { title: 'Informed Decision Making', description: 'Make data-driven decisions with confidence.', icon: <Psychology /> },
    { title: 'Improved Efficiency', description: 'Streamline operations based on data insights.', icon: <Speed /> },
    { title: 'Enhanced Customer Understanding', description: 'Gain deeper insights into customer behavior and preferences.', icon: <Visibility /> },
  ];

  const useCases = [
    'Customer segmentation and targeting',
    'Sales forecasting and demand prediction',
    'Risk assessment and fraud detection',
    'Supply chain optimization',
    'Marketing campaign performance analysis',
  ];

  const content = `
    <h2>Unlocking the Power of Your Data</h2>
    <p>At ARQNXS, we help businesses transform raw data into valuable insights that drive growth and innovation. Our data analytics solutions empower you to make informed decisions, optimize operations, and gain a competitive edge in your industry.</p>
    
    <h3>Our Data Analytics Approach</h3>
    <ol>
      <li><strong>Data Collection:</strong> We help you identify and gather relevant data from various sources.</li>
      <li><strong>Data Cleaning and Preparation:</strong> Our experts ensure your data is accurate, consistent, and ready for analysis.</li>
      <li><strong>Exploratory Data Analysis:</strong> We uncover patterns, trends, and relationships in your data.</li>
      <li><strong>Advanced Analytics:</strong> We apply statistical and machine learning techniques to derive deeper insights.</li>
      <li><strong>Visualization and Reporting:</strong> We present insights in clear, intuitive dashboards and reports.</li>
      <li><strong>Continuous Improvement:</strong> We help you implement a data-driven culture for ongoing success.</li>
    </ol>

    <h3>Technologies We Leverage</h3>
    <ul>
      <li>Python and R for statistical analysis and machine learning</li>
      <li>Tableau, Power BI, and D3.js for data visualization</li>
      <li>Apache Spark for big data processing</li>
      <li>TensorFlow and scikit-learn for predictive modeling</li>
      <li>SQL and NoSQL databases for data storage and retrieval</li>
    </ul>
  `;

  return (
    <ServicePage
      title="Data Analytical Reporting"
      description="Unlock the power of your data with advanced analytics and intuitive reporting."
      icon={<BarChartRounded />}
      features={features}
      benefits={benefits}
      useCases={useCases}
      content={content}
      callToAction="Ready to harness the power of your data? Let's start your analytics journey."
    />
  );
}

export default DataAnalytics;