// src/pages/LatestNews.js
import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ASE_image from './newsimages/ASE_1.png';
import compliance_image from './newsimages/compliance_checker.png';
import toketool_image from './newsimages/toketool.png';
import takdevs from './newsimages/takdevs.png';
import totalmarketeer from './newsimages/totalmarketeer.png'

// This would typically come from an API or CMS
export const newsItems = [
  {
    id: 1,
    title: "ARQNXS and Total Marketeer Join Forces to Revolutionize Digital Advertising",
    date: "July 6, 2024",
    excerpt: "ARQNXS partners with Total Marketeer to develop a groundbreaking blockchain-based platform that brings digital advertising to the physical world.",
    image: totalmarketeer,
    content: `
      <p>We are excited to announce a groundbreaking partnership between ARQNXS and <a href="https://totalmarketeer.com//" target="_blank" rel="noopener noreferrer">Total Marketeer</a>, aimed at revolutionizing the digital advertising landscape. This collaboration will focus on developing a blockchain-based platform that seamlessly integrates digital advertising into the physical world, creating unique and memorable experiences for both advertisers and consumers.</p>

      <h2>Partnership Highlights</h2>
      <ul>
        <li><strong>Blockchain-Powered Advertising:</strong> Leveraging ARQNXS's blockchain expertise to create a transparent and secure advertising platform.</li>
        <li><strong>Unique Ad Experiences:</strong> Combining Total Marketeer's creative vision with ARQNXS's technical prowess to craft one-of-a-kind advertising experiences.</li>
        <li><strong>Democratizing Ad Real Estate:</strong> Making advertising accessible to businesses of all sizes, communities, and individuals, from global corporations to local startups and grassroots initiatives.</li>
        <li><strong>Physical-Digital Integration:</strong> Bridging the gap between digital advertising and the physical world through innovative technologies.</li>
      </ul>

      <h2>Key Features of the New Platform</h2>
      <ul>
        <li><strong>Blockchain-Based Transactions:</strong> Ensuring transparency and security in all advertising transactions.</li>
        <li><strong>Innovative Ad Spaces:</strong> Creating engaging advertising environments that captivate audiences in novel ways.</li>
        <li><strong>Customizable Ad Creations:</strong> Offering tools for businesses, communities, and individuals to create unique, attention-grabbing advertisements.</li>
        <li><strong>Analytics and Insights:</strong> Providing detailed metrics and insights to help advertisers optimize their campaigns.</li>
      </ul>

      <h2>Impact on the Advertising Industry</h2>
      <p>This partnership between ARQNXS and Total Marketeer is set to disrupt the traditional advertising model by:</p>
      <ul>
        <li>Offering more engaging and memorable ad experiences for consumers</li>
        <li>Providing smaller businesses, communities, and individuals with access to high-impact advertising opportunities</li>
        <li>Increasing transparency and trust in digital advertising through blockchain technology</li>
        <li>Fostering creativity and innovation in ad design and placement</li>
      </ul>

      <h2>Looking Ahead</h2>
      <p>As we embark on this exciting journey with Total Marketeer, we are committed to pushing the boundaries of what's possible in digital advertising. Our combined expertise in blockchain, AI, and creative marketing strategies positions us at the forefront of advertising innovation.</p>

      <p>Stay tuned for more updates as we progress in developing this revolutionary platform. Together with Total Marketeer, we're not just changing how ads are displayed – we're transforming how brands, communities, and individuals connect with their audiences in the digital age.</p>
    `,
  },
    {
      id: 2,
      title: "Revolutionizing Machine Learning: The Power of Adaptive Surrogate Ensemble in Hyperparameter Optimization",
      date: "July 11, 2024",
      excerpt: "Introducing the Adaptive Surrogate Ensemble (ASE) method for hyperparameter optimization, pushing the boundaries of what's possible in model tuning and performance.",
      image: ASE_image,
      content: `
        <p>Excited to share a significant breakthrough in the field of machine learning and AutoML! 🎉</p>
        <p>Our latest research introduces the <a href="https://github.com/ARQNXS/Papers/blob/main/ASE/ASE_Paper_Revisioned%20(2).ipynb" target="_blank" rel="noopener noreferrer">Adaptive Surrogate Ensemble</a> method for hyperparameter optimization, pushing the boundaries of what's possible in model tuning and performance. The model and theory is developed by our CEO/Founder/Head of AI <a href="https://www.linkedin.com/in/nigelvanderlaan/" target="_blank" rel="noopener noreferrer">Nigel van der Laan</a>. </p>
        
        <h2>Why This Matters 🔍</h2>
        <p>In the era of increasingly complex ML models, efficient hyperparameter optimization is crucial. Our ASE method addresses this challenge head-on, offering:</p>
        <ol>
          <li>Superior Performance: Consistently outperforms traditional methods like Random Search</li>
          <li>Remarkable Stability: Significantly lower variance in results, ensuring reliability</li>
          <li>Rapid Convergence: Reaches optimal performance in fewer iterations, saving valuable computational resources</li>
        </ol>
  
        <h2>Key Findings 📊</h2>
        <ul>
          <li>On the Digits dataset: ASE achieved 15% higher accuracy with 30% less performance variance compared to Random Search</li>
          <li>On the Breast Cancer dataset: ASE demonstrated superior stability and faster convergence, reaching near-optimal performance in just 5 iterations</li>
        </ul>
  
        <h2>Implications for the Industry 🏭</h2>
        <ol>
          <li>Democratization of Advanced ML: ASE's efficiency makes sophisticated model tuning accessible to a broader range of practitioners</li>
          <li>Resource Optimization: Faster convergence means reduced computational costs and energy consumption</li>
          <li>Enhanced Reliability: Consistent performance is crucial for industrial applications - ASE delivers on this front</li>
        </ol>
  
        <h2>Looking Ahead 🔮</h2>
        <p>This research opens exciting avenues for future work, including:</p>
        <ul>
          <li>Scalability to high-dimensional problems</li>
          <li>Integration with neural architecture search</li>
          <li>Exploration of transfer learning in hyperparameter optimization</li>
        </ul>
  
        <h2>Join🗣️</h2>
        <p>Are you working on machine learning projects facing hyperparameter tuning challenges? I'd love to hear your thoughts and experiences. Let's discuss how methods like ASE could revolutionize your workflow!</p>
      `,
    },
    {
      id: 3,
      title: "Navigating EU AI Act Compliance with Confidence: Introducing the EU AI Act Compliance Checker",
      date: "July 13, 2024",
      excerpt: "ARQNXS launches a free, online EU AI Act Compliance Checker to help businesses assess their AI systems' compliance with the new EU regulations.",
      image: compliance_image,
      content: `
        <p>In the rapidly evolving landscape of artificial intelligence, staying compliant with regulations is more crucial than ever. The European Union's AI Act is set to reshape the way we develop, deploy, and use AI systems. We're excited to share a tool developed to help businesses navigate these complex waters: the EU AI Act Compliance Checker.</p>
  
        <h2>🚀 The Challenge of AI Compliance 🚀</h2>
        <p>The EU AI Act introduces a comprehensive framework for regulating AI systems based on their potential risks. From prohibited practices to strict requirements for high-risk AI applications, the act covers a wide range of scenarios that businesses must consider.</p>
        
        <p>Key aspects of the EU AI Act include:</p>
        <ol>
          <li>🛡️ Risk-based approach to AI regulation</li>
          <li>🔍 Strict requirements for high-risk AI systems</li>
          <li>📜 Transparency and accountability measures</li>
          <li>⚖️ Penalties for non-compliance</li>
        </ol>
  
        <h2>🌐 Introducing the EU AI Act Compliance Checker 🌐</h2>
        <p>We're thrilled to announce the launch of our free, online EU AI Act Compliance Checker. This tool is designed to help businesses of all sizes quickly assess their AI systems' compliance with the EU AI Act.</p>
  
        <p>Key features of our compliance checker:</p>
        <ul>
          <li>📋 Comprehensive Questionnaire: Covers all major aspects of the EU AI Act</li>
          <li>⚡ Instant Results: Get an immediate compliance score and detailed feedback</li>
          <li>🛠️ Actionable Advice: Receive specific recommendations for improving compliance</li>
          <li>🎨 User-Friendly Interface: Easy to use, with no technical expertise required</li>
          <li>🔒 Secure and Confidential: Your data remains private and is not stored</li>
        </ul>
  
        <h2>🛠️ How It Works 🛠️</h2>
        <ol>
          <li>📝 Answer the Questionnaire: Respond to a series of questions about your AI system's purpose, functionality, and current compliance measures.</li>
          <li>📊 Receive Your Compliance Score: Get an instant assessment of your AI system's compliance level.</li>
          <li>🔍 Review Detailed Feedback: Gain insights into specific areas where your system may fall short of compliance requirements.</li>
          <li>🧩 Get Actionable Recommendations: Receive tailored advice on how to improve your compliance status.</li>
          <li>🛤️ Plan Your Next Steps: Use the results to create a roadmap for achieving full compliance with the EU AI Act.</li>
        </ol>
  
        <p>Don't wait until regulations catch up with you. Take the first step towards ensuring your AI systems are compliant with the EU AI Act.</p>
        <p>👉 <a href="https://arqnxs.github.io/eu-ai-act-compliance-checker">Try the EU AI Act Compliance Checker Now</a></p>
      `,
    },
    {
      id: 4,
      title: "Introducing the Advanced Tokenomics Tool: Your Ultimate Solution for Token Economics Planning!",
      date: "July 17, 2024",
      excerpt: "ARQNXS launches a comprehensive tool for tokenomics planning, simulation, and analysis in the blockchain and cryptocurrency space.",
      image: toketool_image,
      content: `
        <p>In the dynamic world of blockchain and cryptocurrency, effective tokenomics planning can be the key to a project's success. Whether you're launching a new token, managing an existing one, or looking to optimize your project's financial strategy, our Advanced Tokenomics Tool is here to help you navigate this complex landscape. Check out the <a href="https://arqnxs.github.io/tokeplanner/" target="_blank" rel="noopener noreferrer">free demo</a> here! </p>
  
        <h2>🎯 Key Features of the Advanced Tokenomics Tool</h2>
        
        <h3>📊 Tokenomics Planning:</h3>
        <ul>
          <li><strong>Customizable Categories:</strong> Easily define and manage categories like Team, Marketing, Development, Investors, and Community with specific percentages, vesting types, and vesting periods.</li>
          <li><strong>Vesting Schedules:</strong> Choose from various vesting types—linear, exponential, logarithmic, and cliff—to emphasize different aspects like team retention, investor incentives, or community engagement.</li>
        </ul>
  
        <h3>🔍 Simulation and Analysis:</h3>
        <ul>
          <li><strong>Token Price Simulation:</strong> Utilize advanced algorithms to simulate token price changes over time, taking into account market conditions, sentiment, and volatility.</li>
          <li><strong>Market Condition Simulation:</strong> Simulate different market conditions, including shock events, to understand their impact on your token price and project stability.</li>
          <li><strong>Monte Carlo Simulation:</strong> Run extensive Monte Carlo simulations to forecast a wide range of potential outcomes and evaluate the probability of different scenarios.</li>
          <li><strong>ROI and Stress Testing:</strong> Simulate return on investment (ROI) and perform stress tests to assess your project's resilience under adverse conditions.</li>
        </ul>
  
        <h3>💸 Fundraising Simulation:</h3>
        <ul>
          <li><strong>Simulate Fundraising Rounds:</strong> Model different fundraising rounds (e.g., Series A) to project new valuations, target raise amounts, and dilution effects on existing token holders.</li>
          <li><strong>New and Existing Investor Integration:</strong> Account for existing investors' pro-rata rights and integrate new investors into the token allocation seamlessly.</li>
        </ul>
  
        <h2>🌟 Why Choose the Advanced Tokenomics Tool?</h2>
        <ul>
          <li><strong>Optimization of Token Allocation:</strong> Achieve your desired outcomes by optimizing the distribution of tokens among different categories.</li>
          <li><strong>Market and Organic Demand Estimation:</strong> Predict how your token will perform in various market scenarios and estimate organic demand.</li>
          <li><strong>Integration of Project Metrics:</strong> Incorporate real-world project metrics like revenue growth, user growth, and product launch success rates into your simulations.</li>
        </ul>
  
        <p>Ready to transform your tokenomics strategy? Check out the advanced version <a href="https://www.youtube.com/watch?v=tw8OYi7OXO4" target="_blank" rel="noopener noreferrer">video demo</a>. Let's get started! 🚀🌐</p>
      `,
    },
    {
        id: 5,
        title: "ARQNXS Partners with TAKdevs to Enhance Blockchain Solutions and Expand Tech Offerings",
        date: "July 20, 2024",
        excerpt: "ARQNXS and TAKdevs join forces to deliver cutting-edge blockchain solutions and expand market reach across a wide range of tech services.",
        image: takdevs,
        content: `
            <p>We are thrilled to announce a strategic partnership between ARQNXS and <a href="https://takdevs.com/" target="_blank" rel="noopener noreferrer">TAKdevs</a>, a leading full-stack software development company. This collaboration marks a significant step forward in our mission to provide comprehensive and innovative technology solutions to our clients across the US, EU, and ME regions.</p>

      
          <h2>Partnership Highlights</h2>
          <ul>
            <li><strong>Enhanced Blockchain Expertise:</strong> Combining ARQNXS's AI and data analytics capabilities with TAKdevs' blockchain proficiency.</li>
            <li><strong>Expanded Service Offering:</strong> Introducing a wide range of new tech services and products to our portfolio.</li>
            <li><strong>Accelerated Innovation:</strong> Joint research and development initiatives to push the boundaries of technology across multiple domains.</li>
            <li><strong>Global Market Reach:</strong> Leveraging both companies' networks to expand our presence in key markets.</li>
          </ul>
      
          <h2>TAKdevs' Comprehensive Capabilities</h2>
          <p>This partnership allows us to significantly expand our service offerings, leveraging TAKdevs' expertise in:</p>
          <ul>
            <li><strong>Web App Development:</strong> Custom, user-friendly web applications tailored for businesses of all sizes.</li>
            <li><strong>Mobile App Development:</strong> High-performance iOS, Android, and cross-platform mobile applications.</li>
            <li><strong>Quality Assurance:</strong> Rigorous testing for functionality, security, and performance.</li>
            <li><strong>Artificial Intelligence:</strong> Innovative AI solutions from chatbots to predictive analytics.</li>
            <li><strong>Machine Learning:</strong> Advanced ML algorithms for pattern analysis, prediction, and task automation.</li>
            <li><strong>UI/UX Design:</strong> Visually appealing and intuitive interfaces that prioritize user experience.</li>
            <li><strong>Cloud & DevOps:</strong> Cutting-edge cloud services and DevOps solutions for digital transformation.</li>
            <li><strong>Digital Marketing:</strong> Comprehensive strategies to drive traffic, increase conversions, and elevate brand presence.</li>
          </ul>
      
          <h2>Industry-Specific Solutions</h2>
          <p>Together, ARQNXS and TAKdevs are poised to deliver transformative solutions across various industries, including:</p>
          <ul>
            <li>Industry & Manufacturing</li>
            <li>Transportation & Logistics</li>
            <li>Healthcare</li>
            <li>Banks & Insurance</li>
            <li>Consulting Providers</li>
            <li>Non-Profit Organizations</li>
          </ul>
      
          <h2>What This Means for Our Clients</h2>
          <p>This partnership allows us to offer:</p>
          <ul>
            <li>More robust and scalable technology solutions across a broader spectrum of services</li>
            <li>Improved integration of AI, blockchain, and other cutting-edge technologies</li>
            <li>Enhanced security, performance, and user experience in digital products</li>
            <li>Tailored solutions for various industries, leveraging both companies' expertise</li>
            <li>Comprehensive support from ideation to deployment and maintenance</li>
          </ul>
      
          <h2>A Shared Commitment to Excellence</h2>
          <p>Both ARQNXS and TAKdevs share a commitment to quality, innovation, and client satisfaction. With TAKdevs' 7-year journey of passion and progress, led by tech enthusiasts and brothers Shaheryar Tariq Khan and Salman Tariq Khan, we are combining our strengths to redefine possibilities with technology.</p>
      
          <h2>Looking Ahead</h2>
          <p>We are excited about the endless possibilities this partnership brings. By combining ARQNXS's expertise in AI and data analytics with TAKdevs' comprehensive software development capabilities, we are poised to deliver unparalleled value to our clients.</p>
      
          <p>Stay tuned for upcoming announcements about new products and services resulting from this dynamic collaboration. Together, we're not just meeting the technology needs of today – we're innovating for the challenges of tomorrow.</p>
        `,
      }
    
  ];

function LatestNews() {
  return (
    <div className="latest-news-page">
      <Container maxWidth="lg">
        <Typography variant="h2" gutterBottom className="page-title">
          Latest News
        </Typography>
        <Grid container spacing={4}>
          {newsItems.map((item) => (
            <Grid item xs={12} md={6} key={item.id}>
              <Card className="news-card">
                <CardMedia
                  component="img"
                  height="200"
                  image={item.image}
                  alt={item.title}
                />
                <CardContent>
                  <Typography variant="h5" gutterBottom>{item.title}</Typography>
                  <Typography variant="subtitle2" gutterBottom>{item.date}</Typography>
                  <Typography variant="body2" paragraph>{item.excerpt}</Typography>
                  <Button component={Link} to={`/news/${item.id}`} variant="outlined" color="primary">
                    Read More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default LatestNews;