// src/components/Footer.js
import React from 'react';
import { Typography, Container, Grid, Link, Box, IconButton } from '@mui/material';
import { Twitter, LinkedIn, GitHub } from '@mui/icons-material';

function Footer() {
  return (
    <Box component="footer" sx={{ bgcolor: 'background.paper', py: 6 }} >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={4}>
            <Box display="flex" alignItems="center" mb={2}>
              <img src={process.env.PUBLIC_URL + '/logo192.png'} alt="ARQNXS Logo" style={{ height: '30px', marginRight: '10px' }} />
              <Typography variant="h6" color="text.primary">
                ARQNXS
              </Typography>
            </Box>
            <Typography variant="body2" color="text.secondary">
              Transforming industries with AI and Data Science
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Email: nigel@arqnxs.com
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Phone: +34 (610) 795-008
            </Typography>
            <Typography variant="body2" color="text.secondary">
              NCAGE: H2R48
            </Typography>
            <Typography variant="body2" color="text.secondary">
              DUNS: 492291297
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Follow Us
            </Typography>
            <Box>
            <IconButton aria-label="twitter" color="primary" component={Link} href="https://x.com/arqnxs" target="_blank" rel="noopener">
              <Twitter />
            </IconButton>
            <IconButton aria-label="linkedin" color="primary" component={Link} href="https://www.linkedin.com/company/arqnxs/" target="_blank" rel="noopener">
              <LinkedIn />
            </IconButton>
            <IconButton aria-label="github" color="primary" component={Link} href="https://github.com/ARQNXS/" target="_blank" rel="noopener">
              <GitHub />
            </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="text.secondary" align="center">
            © {new Date().getFullYear()} Made by ARQNXS. All rights reserved.
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center">
            <Link color="inherit" href="https://www.arqnxs.com">
              www.arqnxs.com
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;