// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import AIMLEngineering from './pages/AIMLEngineering';
import FinTechInnovation from './pages/FinTechInnovation';
import ResearchDevelopment from './pages/ResearchDevelopment';
import FinTech from './pages/industries/FinTech';
import Healthcare from './pages/industries/Healthcare';
import Intelligence from './pages/industries/Intelligence';
import Retail from './pages/industries/Retail';
import Advertisement from './pages/industries/Advertisement';
import Energy from './pages/industries/Energy';
import BlockchainIndustry from './pages/industries/Blockchain';
import Transportation from './pages/industries/Transportation';
import WebDevelopment from './pages/services/WebDevelopment';
import MobileApplication from './pages/services/MobileApplication';
import DataAnalytics from './pages/services/DataAnalytics';
import CloudSolutions from './pages/services/CloudSolutions';
import AIML from './pages/services/AIML';
import Blockchain from './pages/services/Blockchain';
import IoTSolutions from './pages/services/IoTSolutions';
import LatestNews from './pages/LatestNews';
import NewsArticle from './components/NewsArticle';
import ServiceCalculatorPage from './pages/ServiceCalculatorPage'; 
import ResearchAndDevelopment from './pages/services/ResearchAndDevelopment';
import InnovationLab from './pages/services/InnovationLab';
import CollaborativeResearch from './pages/services/CollaborativeResearch';

import './App.css';
import ScrollToTop from './ScrollToTop'; // Import ScrollToTop component



function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ScrollToTop /> {/* Use ScrollToTop component */}
        <div className="app-container">
          <Header />
          <main className="main-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/ai-ml-engineering" element={<AIMLEngineering />} />
              <Route path="/fintech-innovation" element={<FinTechInnovation />} />
              <Route path="/research-development" element={<ResearchDevelopment />} />
              <Route path="/industries/fintech" element={<FinTech />} />
              <Route path="/industries/healthcare" element={<Healthcare />} />
              <Route path="/industries/intelligence" element={<Intelligence />} />
              <Route path="/industries/retail" element={<Retail />} />
              <Route path="/industries/advertisement" element={<Advertisement />} />
              <Route path="/industries/energy" element={<Energy />} />
              <Route path="/industries/blockchain" element={<BlockchainIndustry />} />
              <Route path="/industries/transportation" element={<Transportation />} />
              <Route path="/services/web-development" element={<WebDevelopment />} />
              <Route path="/services/mobile-application" element={<MobileApplication />} />
              <Route path="/services/data-analytics" element={<DataAnalytics />} />
              <Route path="/services/cloud-solutions" element={<CloudSolutions />} />
              <Route path="/services/ai-ml" element={<AIML />} />
              <Route path="/services/blockchain" element={<Blockchain />} />
              <Route path="/services/iot" element={<IoTSolutions />} />
              <Route path="/news" element={<LatestNews />} />
              <Route path="/news/:id" element={<NewsArticle />} />
              <Route path="/servicecalculator" element={<ServiceCalculatorPage />} /> 
              <Route path="services/researchanddevelopment" element={<ResearchAndDevelopment />} /> 
              <Route path="services/innovationlab" element={<InnovationLab/>} /> 
              <Route path="services/collaborativeresearch" element={<CollaborativeResearch/>} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;