import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Container, MenuItem, Grid, Box, Popper, Fade, Paper, ClickAwayListener, IconButton, Drawer, List, ListItem, ListItemText, Collapse } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { ExpandMore, ChevronRight, Menu as MenuIcon, ExpandLess } from '@mui/icons-material';
import './Header.css';
import logo from '../assets/arqnxs.svg';

function Header() {
  const [servicesAnchorEl, setServicesAnchorEl] = useState(null);
  const [industryAnchorEl, setIndustryAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [mobileServicesOpen, setMobileServicesOpen] = useState(false);
  const [mobileIndustryOpen, setMobileIndustryOpen] = useState(false);
  const navigate = useNavigate();

  const handleServicesClick = (event) => {
    setServicesAnchorEl(servicesAnchorEl ? null : event.currentTarget);
  };

  const handleIndustryClick = (event) => {
    setIndustryAnchorEl(industryAnchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setServicesAnchorEl(null);
    setIndustryAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMobileServicesToggle = (event) => {
    event.stopPropagation();
    setMobileServicesOpen(!mobileServicesOpen);
  };

  const handleMobileIndustryToggle = (event) => {
    event.stopPropagation();
    setMobileIndustryOpen(!mobileIndustryOpen);
  };

  const handleMobileNavigation = (path) => {
    navigate(path);
    setMobileOpen(false);
  };

  const services = [
    {
      category: "Core Services",
      items: [
        { name: "AI/ML Engineering", path: "/ai-ml-engineering" },
        { name: "FinTech Innovation", path: "/fintech-innovation" },
        { name: "Research & Development", path: "/research-development" },
        { name: "Cost Reduction Calculator", path: "/servicecalculator" },
      ]
    },
    {
      category: "Technology Solutions",
      items: [
        { name: "Web Development", path: "/services/web-development" },
        { name: "Mobile Application", path: "/services/mobile-application" },
        { name: "Data Analytical Reporting", path: "/services/data-analytics" },
        { name: "Cloud Solutions", path: "/services/cloud-solutions" },
        { name: "AI and Machine Learning", path: "/services/ai-ml" },
        { name: "Blockchain Development", path: "/services/blockchain" },
        { name: "IoT Solutions", path: "/services/iot" },
      ]
    }
  ];

  const industries = [
    { name: "FinTech & Banking", path: "/industries/fintech" },
    { name: "Healthcare & Life Sciences", path: "/industries/healthcare" },
    { name: "Intelligence Operatives", path: "/industries/intelligence" },
    { name: "Retail & E-commerce", path: "/industries/retail" },
    { name: "Advertisement", path: "/industries/advertisement" },
    { name: "Energy & Utilities", path: "/industries/energy" },
    { name: "Blockchain Technology", path: "/industries/blockchain" },
    { name: "Transportation & Logistics", path: "/industries/transportation" },
  ];

  const drawer = (
    <Box>
      <Typography variant="h6" sx={{ my: 2, textAlign: 'center' }}>
        ARQNXS
      </Typography>
      <List>
        <ListItem button onClick={() => handleMobileNavigation('/')}>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button onClick={handleMobileServicesToggle}>
          <ListItemText primary="Services" />
          {mobileServicesOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={mobileServicesOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {services.map((category) => (
              <React.Fragment key={category.category}>
                <ListItem sx={{ pl: 4 }}>
                  <ListItemText primary={category.category} />
                </ListItem>
                {category.items.map((item) => (
                  <ListItem button key={item.name} onClick={() => handleMobileNavigation(item.path)} sx={{ pl: 6 }}>
                    <ListItemText primary={item.name} />
                  </ListItem>
                ))}
              </React.Fragment>
            ))}
          </List>
        </Collapse>
        <ListItem button onClick={handleMobileIndustryToggle}>
          <ListItemText primary="Industry Focus" />
          {mobileIndustryOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={mobileIndustryOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {industries.map((industry) => (
              <ListItem button key={industry.name} onClick={() => handleMobileNavigation(industry.path)} sx={{ pl: 4 }}>
                <ListItemText primary={industry.name} />
              </ListItem>
            ))}
          </List>
        </Collapse>
        <ListItem button onClick={() => handleMobileNavigation('/about')}>
          <ListItemText primary="About" />
        </ListItem>
        <ListItem button onClick={() => handleMobileNavigation('/news')}>
          <ListItemText primary="Latest News" />
        </ListItem>
        <ListItem button onClick={() => handleMobileNavigation('/contact')}>
          <ListItemText primary="Contact" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar position="static" className="header" style={{ backgroundColor: '#0b2a63' }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <Link to="/" className="logo">
              <img src={logo} alt="ARQNXS Logo" style={{ height: '80px', marginRight: '5px' }} />
            </Link>
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <nav>
              <Button color="inherit" component={Link} to="/">Home</Button>
              <Button color="inherit" onClick={handleServicesClick} endIcon={<ExpandMore />}>
                Services
              </Button>
              <Button color="inherit" onClick={handleIndustryClick} endIcon={<ExpandMore />}>
                Industry Focus
              </Button>
              <Button color="inherit" component={Link} to="/about">About</Button>
              <Button color="inherit" component={Link} to="/news">Latest News</Button>
              <Button color="inherit" component={Link} to="/contact">Contact</Button>
            </nav>
          </Box>
        </Toolbar>
      </Container>
      <Popper
        open={Boolean(servicesAnchorEl)}
        anchorEl={servicesAnchorEl}
        placement="bottom-start"
        transition
        className="services-menu"
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ p: 2, width: '600px' }}>
                  {services.map((category, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                        {category.category}
                      </Typography>
                      <Grid container spacing={1}>
                        {category.items.map((service, serviceIndex) => (
                          <Grid item xs={6} key={serviceIndex}>
                            <MenuItem onClick={handleClose} component={Link} to={service.path}>
                              <ChevronRight /> {service.name}
                            </MenuItem>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  ))}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Popper
        open={Boolean(industryAnchorEl)}
        anchorEl={industryAnchorEl}
        placement="bottom-start"
        transition
        className="industry-menu"
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Box>
                  <Grid container spacing={2} sx={{ p: 2, width: '600px' }}>
                    {industries.map((industry, index) => (
                      <Grid item xs={6} key={index}>
                        <MenuItem onClick={handleClose} component={Link} to={industry.path}>
                          <ChevronRight /> {industry.name}
                        </MenuItem>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
}

export default Header;