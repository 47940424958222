// src/pages/services/Blockchain.js
import React from 'react';
import ServicePage from './ServicePage';
import { LinkRounded, DescriptionRounded, GroupsRounded, Security, AccountBalance, Speed } from '@mui/icons-material';

function Blockchain() {
  const features = [
    { 
      title: 'Smart Contracts', 
      description: 'Develop and deploy secure, efficient smart contracts.',
      icon: <DescriptionRounded />
    },
    { 
      title: 'Decentralized Apps (DApps)', 
      description: 'Create innovative decentralized applications for various use cases.',
      icon: <LinkRounded />
    },
    { 
      title: 'Blockchain Consulting', 
      description: 'Expert guidance on leveraging blockchain technology for your business.',
      icon: <GroupsRounded />
    },
  ];

  const benefits = [
    { title: 'Enhanced Security', description: 'Leverage cryptographic security for tamper-proof transactions.', icon: <Security /> },
    { title: 'Increased Transparency', description: 'Achieve full visibility and traceability of all transactions.', icon: <AccountBalance /> },
    { title: 'Improved Efficiency', description: 'Streamline processes and reduce intermediaries.', icon: <Speed /> },
  ];

  const useCases = [
    'Supply chain management and traceability',
    'Secure digital identity management',
    'Decentralized finance (DeFi) applications',
    'Intellectual property and royalty distribution',
    'Voting systems and governance',
  ];

  const content = `
    <h2>Revolutionizing Industries with Blockchain Technology</h2>
    <p>At ARQNXS, we're at the forefront of blockchain innovation, helping businesses leverage this groundbreaking technology to enhance security, transparency, and efficiency. Our blockchain solutions are tailored to address specific industry challenges and unlock new opportunities for growth.</p>
    
    <h3>Our Blockchain Expertise</h3>
    <p>We offer comprehensive blockchain services, including:</p>
    <ul>
      <li><strong>Blockchain Strategy Consulting:</strong> We help you identify the right blockchain use cases for your business and develop a robust implementation strategy.</li>
      <li><strong>Smart Contract Development:</strong> Our experts design and implement secure, efficient smart contracts to automate and enforce business logic.</li>
      <li><strong>DApp Development:</strong> We build decentralized applications that leverage blockchain's unique features to deliver innovative solutions.</li>
      <li><strong>Blockchain Integration:</strong> We seamlessly integrate blockchain technology with your existing systems and workflows.</li>
      <li><strong>Private Blockchain Networks:</strong> We design and deploy custom blockchain networks tailored to your specific business needs.</li>
    </ul>

    <h3>Technologies We Work With</h3>
    <p>Our blockchain solutions leverage various platforms and technologies, including:</p>
    <ul>
      <li>Ethereum and Solidity for smart contracts</li>
      <li>Hyperledger Fabric for enterprise blockchain solutions</li>
      <li>Binance Smart Chain for DeFi applications</li>
      <li>IPFS for decentralized storage</li>
      <li>Web3.js and ethers.js for blockchain interactions</li>
    </ul>
  `;

  return (
    <ServicePage
      title="Blockchain Development"
      description="Harness the power of blockchain technology to enhance security, transparency, and efficiency."
      icon={<LinkRounded />}
      features={features}
      benefits={benefits}
      useCases={useCases}
      content={content}
      callToAction="Ready to explore blockchain solutions? Let's innovate together."
    />
  );
}

export default Blockchain;