import React from 'react';
import ServicePage from './ServicePage';
import { GroupsRounded, SchoolRounded, BusinessRounded, Handshake, TrendingUp, Public } from '@mui/icons-material';

function CollaborativeResearch() {
  const features = [
    { 
      title: 'Academic Partnerships', 
      description: 'Collaborate with leading universities and research institutions.',
      icon: <SchoolRounded />
    },
    { 
      title: 'Industry Alliances', 
      description: 'Join forces with industry leaders to tackle shared challenges.',
      icon: <BusinessRounded />
    },
    { 
      title: 'Multidisciplinary Teams', 
      description: 'Bring together diverse expertise to drive innovation.',
      icon: <GroupsRounded />
    },
  ];

  const benefits = [
    { title: 'Shared Resources', description: 'Access a wider pool of knowledge, facilities, and funding.', icon: <Handshake /> },
    { title: 'Accelerated Discovery', description: 'Combine efforts to achieve breakthroughs faster.', icon: <TrendingUp /> },
    { title: 'Global Impact', description: 'Address large-scale challenges with far-reaching implications.', icon: <Public /> },
  ];

  const useCases = [
    'Cross-industry research initiatives',
    'Joint academic-industry projects',
    'Open innovation challenges',
    'Collaborative patent development',
    'Shared technology platforms',
  ];

  const content = `
    <h2>Driving Progress Through Collaborative Research</h2>
    <p>At ARQNXS, we believe that the most significant breakthroughs happen when great minds come together. Our Collaborative Research program fosters partnerships between academia, industry, and our own experts to tackle complex challenges and drive innovation.</p>
    
    <h3>Our Collaborative Approach</h3>
    <ol>
      <li><strong>Identify Opportunities:</strong> We pinpoint areas where collaboration can yield the greatest impact.</li>
      <li><strong>Form Partnerships:</strong> We bring together complementary expertise from various sectors.</li>
      <li><strong>Define Projects:</strong> We collaboratively scope research initiatives with clear objectives.</li>
      <li><strong>Execute Research:</strong> Our multidisciplinary teams work together to conduct groundbreaking research.</li>
      <li><strong>Share Outcomes:</strong> We ensure that findings are disseminated and applied effectively.</li>
    </ol>

    <h3>Types of Collaborations</h3>
    <ul>
      <li><strong>Joint Research Projects:</strong> Long-term collaborations on specific research themes.</li>
      <li><strong>Sponsored Research:</strong> Funding academic research aligned with industry needs.</li>
      <li><strong>Consortium Projects:</strong> Multi-party collaborations to address industry-wide challenges.</li>
      <li><strong>Open Innovation Initiatives:</strong> Crowdsourcing solutions from a global community of experts.</li>
    </ul>

    <h3>Key Focus Areas</h3>
    <p>Our collaborative research efforts currently focus on:</p>
    <ul>
      <li>Sustainable Energy Solutions</li>
    
      <li>AI and Machine Learning Applications</li>
      <li>Biotechnology and Healthcare Innovations</li>
      <li>Smart Cities and IoT</li>
    </ul>

    <h3>Partnership Benefits</h3>
    <p>By participating in our Collaborative Research program, partners can:</p>
    <ul>
      <li>Access cutting-edge research and technologies</li>
      <li>Share risks and costs of ambitious research projects</li>
      <li>Enhance their innovation capabilities</li>
      <li>Gain early insights into emerging trends and technologies</li>
      <li>Contribute to solving global challenges</li>
    </ul>
  `;

  return (
    <ServicePage
      title="Collaborative Research"
      description="Partnering with academia and industry leaders for joint research projects that drive innovation."
      icon={<GroupsRounded />}
      features={features}
      benefits={benefits}
      useCases={useCases}
      content={content}
      callToAction="Interested in collaborative research? Let's explore partnership opportunities."
    />
  );
}

export default CollaborativeResearch;