import React, { useState } from 'react';
import { 
  Typography, 
  Box, 
  Card, 
  CardContent,
  Slider,
  Grid,
  Divider,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: '22px 0',
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: theme.palette.primary.main,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
  '& .MuiSlider-track': {
    height: 4,
  },
  '& .MuiSlider-rail': {
    height: 4,
  },
}));

const ResultCard = ({ icon, title, value }) => (
  <Card raised sx={{ height: '100%' }}>
    <CardContent>
      <Stack direction="row" spacing={2} alignItems="center">
        {icon}
        <Box>
          <Typography variant="body2" color="text.secondary">
            {title}
          </Typography>
          <Typography variant="h5" component="div">
            {value}
          </Typography>
        </Box>
      </Stack>
    </CardContent>
  </Card>
);

const currencies = {
    USD: '$',  // United States Dollar
    EUR: '€',  // Euro
    JPY: '¥',  // Japanese Yen
    GBP: '£',  // British Pound Sterling
    CNY: '¥',  // Chinese Yuan (Renminbi)
    AUD: 'A$', // Australian Dollar
    CAD: 'C$', // Canadian Dollar
    CHF: 'Fr', // Swiss Franc
    HKD: 'HK$', // Hong Kong Dollar
    SGD: 'S$', // Singapore Dollar
    INR: '₹',  // Indian Rupee
    MXN: 'Mex$' // Mexican Peso
  };

const StyledLabel = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
    fontSize: '1rem',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    paddingBottom: theme.spacing(0.5),
    display: 'inline-block'
  }));

const CalculatorSection = ({ manualHours, setManualHours, hourlyRate, setHourlyRate, calculateHoursReduced, getNumericHoursReduced, remainingHours, costSavings }) => {
  const [currency, setCurrency] = useState('GBP');
  const totalCostBeforeReduction = manualHours * hourlyRate;

  const formatCurrency = (value) => {
    return `${currencies[currency]}${value.toFixed(2)}`;
  };

  // Calculate efficiency improvement
  const efficiencyImprovement = manualHours > 0
    ? Math.min((getNumericHoursReduced() / manualHours) * 100, 100).toFixed(2)
    : '0.00';

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
        Discover Your Cost Reduction
      </Typography>
      <Grid container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 2 }}>
          <StyledLabel>
                Current manual labor hours per month
            </StyledLabel>
            <Box sx={{ px: 0, pt: 4 }}> {/* Add padding around the slider */}
              <StyledSlider
                value={manualHours}
                onChange={(_, newValue) => setManualHours(newValue)}
                min={0}
                max={1500}
                valueLabelDisplay="auto"
              />
            </Box>
          </Box>
          <Typography variant="h6" align="center">{manualHours} hours</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
            <StyledLabel>
                Hourly Rate
            </StyledLabel>
            <FormControl sx={{ minWidth: 120, mt: -1 }}>
            <InputLabel id="currency-select-label">Currency</InputLabel>
            <Select
                labelId="currency-select-label"
                value={currency}
                label="Currency"
                onChange={(e) => setCurrency(e.target.value)}
                size="small"
            >
                {Object.entries(currencies).map(([code, symbol]) => (
                <MenuItem key={code} value={code}>
                    {code} ({symbol})
                </MenuItem>
                ))}
            </Select>
            </FormControl>
            </Stack>
            <Box sx={{ px: 0, pt: 4 }}> {/* Add padding around the slider */}
              <StyledSlider
                value={hourlyRate}
                onChange={(_, newValue) => setHourlyRate(newValue)}
                min={0}
                max={500}
                valueLabelDisplay="auto"
              />
            </Box>
          </Box>
          <Typography variant="h6" align="center">{formatCurrency(hourlyRate)}</Typography>
        </Grid>
      </Grid>
      
      <Divider sx={{ my: 4 }} />
      
      <Typography variant="h5" gutterBottom align="center" sx={{ mb: 3 }}>
        Estimated Results
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <ResultCard 
            icon={<AttachMoneyIcon fontSize="large" color="info" />}
            title="Total Cost Before Reduction"
            value={formatCurrency(totalCostBeforeReduction)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ResultCard 
            icon={<AccessTimeIcon fontSize="large" color="primary" />}
            title="Estimated Hours Reduced"
            value={`${calculateHoursReduced()} hrs`}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ResultCard 
            icon={<AccessTimeIcon fontSize="large" color="secondary" />}
            title="Remaining Hours"
            value={`${remainingHours} hrs`}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ResultCard 
            icon={<TrendingUpIcon fontSize="large" color="success" />}
            title="Efficiency Improvement"
            value={`${efficiencyImprovement}%`}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ResultCard 
            icon={<MonetizationOnIcon fontSize="large" color="error" />}
            title="Monthly Savings"
            value={formatCurrency(costSavings)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ResultCard 
            icon={<AttachMoneyIcon fontSize="large" color="warning" />}
            title="Total Cost After Reduction"
            value={formatCurrency(totalCostBeforeReduction - costSavings)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CalculatorSection;