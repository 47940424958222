import React from 'react';
import IndustryTemplate from './IndustryTemplate';
import { Container, Typography, Box, Chip } from '@mui/material';

function Retail() {
  const title = "Retail & E-commerce Solutions";
  const description = "ARQNXS empowers retail and e-commerce businesses with AI-driven solutions that enhance customer experiences, optimize inventory management, and drive sales growth.";
  const solutions = [
    { 
      title: "Personalized Recommendations", 
      description: "Implement AI algorithms for personalized product recommendations to boost sales.",
      useCases: [
        "Dynamic product recommendations based on browsing history",
        "Personalized email marketing campaigns",
        "Cross-selling and upselling suggestions"
      ],
      technologies: ["Collaborative Filtering", "Content-Based Filtering", "Deep Learning"]
    },
    { 
      title: "Inventory Optimization", 
      description: "Utilize machine learning for accurate demand forecasting and inventory management.",
      useCases: [
        "Predictive demand forecasting",
        "Automated reordering systems",
        "Dynamic pricing optimization"
      ],
      technologies: ["Time Series Analysis", "Reinforcement Learning", "Big Data Analytics"]
    },
    { 
      title: "Chatbot Customer Service", 
      description: "Deploy AI-powered chatbots for 24/7 customer support and improved user experience.",
      useCases: [
        "Automated order tracking and returns",
        "Product information and recommendations",
        "First-line customer support and FAQ handling"
      ],
      technologies: ["Natural Language Processing", "Machine Learning", "Sentiment Analysis"]
    },
  ];

  const benefits = [
    "Increased sales and customer retention",
    "Improved inventory turnover and reduced costs",
    "Enhanced customer experience and satisfaction",
    "Data-driven decision making",
    "Scalable and efficient operations"
  ];

  return (
    <IndustryTemplate title={title} description={description} solutions={solutions}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>Key Benefits</Typography>
        <Box display="flex" flexWrap="wrap" gap={1} mb={4}>
          {benefits.map((benefit, index) => (
            <Chip key={index} label={benefit} color="primary" />
          ))}
        </Box>
        <Typography variant="h4" gutterBottom>Our Impact</Typography>
        <Typography variant="body1" paragraph>
          ARQNXS has helped numerous retail and e-commerce businesses transform their operations through AI-driven solutions. Our clients have seen an average increase of 25% in sales, 30% improvement in inventory turnover, and 40% reduction in customer service costs. We work closely with businesses of all sizes to implement tailored solutions that drive growth and improve customer satisfaction in the competitive retail landscape.
        </Typography>
      </Container>
    </IndustryTemplate>
  );
}

export default Retail;