import React from 'react';
import IndustryTemplate from './IndustryTemplate';
import { Container, Typography, Box, Chip } from '@mui/material';

function Energy() {
  const title = "Energy & Utilities Solutions";
  const description = "ARQNXS provides cutting-edge AI and ML solutions for the energy and utilities sector, optimizing resource management, predicting maintenance needs, and enhancing grid efficiency.";
  const solutions = [
    { 
      title: "Smart Grid Management", 
      description: "Implement AI-driven systems for efficient energy distribution and load balancing.",
      useCases: [
        "Real-time demand forecasting",
        "Automated grid balancing",
        "Dynamic pricing optimization"
      ],
      technologies: ["Machine Learning", "IoT", "Big Data Analytics"]
    },
    { 
      title: "Predictive Maintenance", 
      description: "Utilize machine learning to predict equipment failures and optimize maintenance schedules.",
      useCases: [
        "Asset health monitoring",
        "Failure prediction for power plants",
        "Optimized maintenance scheduling"
      ],
      technologies: ["Predictive Analytics", "Sensor Data Processing", "Digital Twin Technology"]
    },
    { 
      title: "Renewable Energy Optimization", 
      description: "Develop AI algorithms to maximize the efficiency of renewable energy sources.",
      useCases: [
        "Solar and wind power output prediction",
        "Energy storage optimization",
        "Microgrid management"
      ],
      technologies: ["Machine Learning", "Weather Data Integration", "Optimization Algorithms"]
    },
  ];

  const benefits = [
    "Improved energy efficiency",
    "Reduced downtime and maintenance costs",
    "Enhanced grid reliability and resilience",
    "Optimized renewable energy integration",
    "Reduced carbon footprint"
  ];

  return (
    <IndustryTemplate title={title} description={description} solutions={solutions}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>Key Benefits</Typography>
        <Box display="flex" flexWrap="wrap" gap={1} mb={4}>
          {benefits.map((benefit, index) => (
            <Chip key={index} label={benefit} color="primary" />
          ))}
        </Box>
        <Typography variant="h4" gutterBottom>Our Impact</Typography>
        <Typography variant="body1" paragraph>
          ARQNXS is committed to driving the transition to a more sustainable and efficient energy future. Our AI-powered solutions have helped utility companies reduce operational costs by up to 20%, improve grid reliability by 15%, and increase the integration of renewable energy sources by 30%. We work closely with energy providers to develop customized solutions that address their specific challenges and goals.
        </Typography>
      </Container>
    </IndustryTemplate>
  );
}

export default Energy;