import React from 'react';
import ServicePage from './ServicePage';
import { ScienceRounded, DesignServicesRounded, InventoryRounded, Rocket, EmojiObjects, Biotech } from '@mui/icons-material';

function ResearchAndDevelopment() {
  const features = [
    { 
      title: 'Cutting-Edge Research', 
      description: 'Explore new technologies and methodologies to drive innovation.',
      icon: <ScienceRounded />
    },
    { 
      title: 'Prototype Development', 
      description: 'Rapidly create and iterate on prototypes to validate concepts.',
      icon: <DesignServicesRounded />
    },
    { 
      title: 'Technology Assessment', 
      description: 'Evaluate emerging technologies for potential business applications.',
      icon: <InventoryRounded />
    },
  ];

  const benefits = [
    { title: 'Competitive Edge', description: 'Stay ahead of the curve with innovative solutions.', icon: <Rocket /> },
    { title: 'New Product Development', description: 'Transform ideas into marketable products and services.', icon: <EmojiObjects /> },
    { title: 'Scientific Breakthroughs', description: 'Contribute to advancements in your industry.', icon: <Biotech /> },
  ];

  const useCases = [
    'Next-generation product development',
    'Sustainable technology research',
    'Process optimization studies',
    'Emerging technology integration',
  ];

  const content = `
    <h2>Driving Innovation through Research and Development</h2>
    <p>At ARQNXS, our Research and Development team is dedicated to pushing the boundaries of what's possible. We combine scientific expertise with creative problem-solving to deliver groundbreaking solutions that propel your business forward.</p>
    
    <h3>Our R&D Process</h3>
    <p>We follow a structured yet flexible approach to research and development:</p>
    <ol>
      <li><strong>Discovery:</strong> We identify key areas for innovation and potential research directions.</li>
      <li><strong>Conceptualization:</strong> Our team develops initial concepts and hypotheses for exploration.</li>
      <li><strong>Experimentation:</strong> We conduct rigorous experiments and prototype testing.</li>
      <li><strong>Analysis:</strong> We analyze results and refine our approach based on findings.</li>
      <li><strong>Implementation:</strong> We translate successful research into practical applications.</li>
    </ol>

    <h3>Areas of Focus</h3>
    <p>Our R&D efforts span a wide range of disciplines, including:</p>
    <ul>
   
      <li>Biotechnology and Life Sciences</li>
      <li>Clean Energy and Sustainability</li>
      <li>Robotics and Automation</li>
      <li>Cutting edge Machine Learning Optimization</li>

    </ul>

    <h3>Collaboration and Partnerships</h3>
    <p>We believe in the power of collaboration. Our R&D team works closely with:</p>
    <ul>
      <li>Academic institutions</li>
      <li>Industry partners</li>
      <li>Government research agencies</li>
      <li>Startup incubators</li>
    </ul>
    <p>These partnerships allow us to leverage diverse expertise and resources, accelerating the pace of innovation.</p>
  `;

  return (
    <ServicePage
      title="Research and Development"
      description="Innovate and stay ahead of the competition with our cutting-edge R&D services."
      icon={<ScienceRounded />}
      features={features}
      benefits={benefits}
      useCases={useCases}
      content={content}
      callToAction="Ready to innovate? Let's explore the possibilities together."
    />
  );
}

export default ResearchAndDevelopment;