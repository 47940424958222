// src/pages/ResearchDevelopment.js
import React from 'react';
import { Container, Typography, Grid, Paper, Box, Button } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import './ResearchDevelopment.css';

// Import your Lottie JSON files
import emergingTechAnimation from './icons/emerging-tech.json';
import innovationLabAnimation from './icons/innovation-lab.json';
import collaborativeResearchAnimation from './icons/collaborative-research.json';

function ResearchDevelopment() {
  const rdAreas = [
    { title: 'Emerging Technologies', description: 'Exploring cutting-edge technologies to drive future innovations.', animation: emergingTechAnimation, link: '/services/researchanddevelopment' },
    { title: 'Innovation Lab', description: 'Our dedicated space for experimenting with breakthrough ideas.', animation: innovationLabAnimation, link: '/services/innovationlab' },
    { title: 'Collaborative Research', description: 'Partnering with academia and industry leaders for joint research projects.', animation: collaborativeResearchAnimation, link: '/services/collaborativeresearch' },
  ];

  return (
    <div className="research-development">
      <Container maxWidth="lg">
        <Typography variant="h2" gutterBottom className="page-title">
          Research & Development
        </Typography>
        <Typography variant="h5" paragraph className="page-subtitle">
          Driving breakthrough discoveries and pioneering innovations.
        </Typography>

        <Grid container spacing={4}>
          {rdAreas.map((area, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Paper elevation={3} className="area-card">
                <Box className="area-icon">
                  <Lottie animationData={area.animation} loop={true} />
                </Box>
                <Typography variant="h6" gutterBottom>{area.title}</Typography>
                <Typography variant="body2">{area.description}</Typography>
                <Button component={Link} to={area.link} endIcon={<ArrowForward />} sx={{ mt: 2 }}>
                  Learn More
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Box mt={6}>
          <Typography variant="h4" gutterBottom>Pioneering the Future of Technology</Typography>
          <Typography variant="body1" paragraph>
            At ARQNXS, our Research and Development team is dedicated to pushing the boundaries of what's possible in technology. We invest heavily in exploring emerging technologies, conducting original research, and developing innovative solutions that address the most pressing challenges faced by businesses and society.
          </Typography>
          <Typography variant="body1" paragraph>
            Our R&D efforts span a wide range of fields, including artificial intelligence, neuroscience, psychology, and sustainable technologies. By collaborating with leading academic institutions and industry partners, we ensure that our research has real-world applications and drives meaningful progress in various sectors.
          </Typography>
          <Button component={Link} to="/services/researchanddevelopment" variant="contained" color="primary" endIcon={<ArrowForward />}>
            Learn About Our R&D Initiatives
          </Button>
        </Box>
      </Container>
    </div>
  );
}

export default ResearchDevelopment;