import React from 'react';
import ServicePage from './ServicePage';
import { LightbulbRounded, BuildRounded, GroupsRounded, RocketLaunch, TrendingUp, Psychology } from '@mui/icons-material';

function InnovationLab() {
  const features = [
    { 
      title: 'State-of-the-Art Facilities', 
      description: 'Access cutting-edge equipment and technologies for experimentation.',
      icon: <LightbulbRounded />
    },
    { 
      title: 'Rapid Prototyping', 
      description: 'Quickly turn ideas into tangible prototypes for testing and iteration.',
      icon: <BuildRounded />
    },
    { 
      title: 'Collaborative Workspace', 
      description: 'Foster creativity and cross-disciplinary collaboration in our open environment.',
      icon: <GroupsRounded />
    },
  ];

  const benefits = [
    { title: 'Accelerated Innovation', description: 'Reduce time-to-market for new ideas and products.', icon: <RocketLaunch /> },
    { title: 'Risk Mitigation', description: 'Test and validate concepts before full-scale investment.', icon: <TrendingUp /> },
    { title: 'Culture of Innovation', description: 'Cultivate a mindset of continuous improvement and creativity.', icon: <Psychology /> },
  ];

  const useCases = [
    'Developing disruptive technologies',
    'Creating proof-of-concept prototypes',
    'Conducting user experience testing',
    'Exploring emerging market opportunities',
    'Solving complex industry challenges',
  ];

  const content = `
    <h2>Unleash Your Creativity in Our Innovation Lab</h2>
    <p>ARQNXS's Innovation Lab is a dedicated space where ideas come to life. Our state-of-the-art facility provides the perfect environment for experimentation, collaboration, and breakthrough thinking.</p>
    
    <h3>Lab Features</h3>
    <ul>
      <li><strong>3D Printing Lab:</strong> Rapid prototyping capabilities for physical product development.</li>
      <li><strong>VR/AR Studio:</strong> Explore and develop immersive experiences and applications.</li>
      <li><strong>IoT Testbed:</strong> Experiment with connected devices and smart systems.</li>
      <li><strong>AI Workstations:</strong> High-performance computing for machine learning and data analysis.</li>
      <li><strong>Brainstorming Zones:</strong> Collaborative spaces designed to inspire creativity.</li>
    </ul>

    <h3>Innovation Programs</h3>
    <p>Our Innovation Lab hosts a variety of programs to drive creativity and problem-solving:</p>
    <ul>
      <li><strong>Hackathons:</strong> Intense, time-bound events to solve specific challenges.</li>
      <li><strong>Innovation Workshops:</strong> Guided sessions to explore new ideas and methodologies.</li>
      <li><strong>Tech Talks:</strong> Regular presentations on emerging technologies and trends.</li>
      <li><strong>Startup Incubation:</strong> Support for promising internal and external ventures.</li>
    </ul>

    <h3>Access and Support</h3>
    <p>The Innovation Lab is available to:</p>
    <ul>
      <li>ARQNXS employees for internal projects</li>
      <li>Clients as part of our consulting services</li>
      <li>Partners for collaborative research initiatives</li>
    </ul>
    <p>Our team of innovation experts is always on hand to provide guidance, technical support, and help in transforming ideas into reality.</p>
  `;

  return (
    <ServicePage
      title="Innovation Lab"
      description="A dedicated space for experimenting with breakthrough ideas and turning concepts into reality."
      icon={<LightbulbRounded />}
      features={features}
      benefits={benefits}
      useCases={useCases}
      content={content}
      callToAction="Ready to innovate? Book a session in our Innovation Lab today."
    />
  );
}

export default InnovationLab;