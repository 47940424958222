import React from 'react';
import './Values.css';
import { Typography, Box, Container, Grid } from '@mui/material';
import Lottie from 'lottie-react';

// Import your Lottie JSON files
import innovationAnimation from './icons/innovative-vision.json';
import excellenceAnimation from './icons/excellence.json';
import integrityAnimation from './icons/integrity.json';
import collaborationAnimation from './icons/collaboration.json';
import clientFocusAnimation from './icons/client-focus.json';
import growthAnimation from './icons/growth.json';

const ValueItem = ({ animationData, title, description }) => (
  <Box className="value-item">
    <Box className="icon-container">
      <Lottie 
        animationData={animationData} 
        loop={true}
        autoplay={true}
        style={{ width: 100, height: 100 }}
        className="animated-icon"
      />
    </Box>
    <Typography variant="h6" className="value-title">
      {title}
    </Typography>
    <Typography variant="body2" className="value-description">
      {description}
    </Typography>
  </Box>
);

const Values = () => {
  const valueItems = [
    { animationData: innovationAnimation, title: "Innovative Vision", description: "We drive innovation, encouraging our team to break the mold and deliver cutting-edge solutions." },
    { animationData: excellenceAnimation, title: "Striving for Excellence", description: "Excellence is our standard. We pledge to produce work of the finest quality in every facet." },
    { animationData: integrityAnimation, title: "Commitment to Integrity", description: "We adhere to the highest ethical principles, building trust through transparency and fairness." },
    { animationData: collaborationAnimation, title: "Collaborative Power", description: "We leverage diverse skills through open dialogue and mutual support to achieve outstanding outcomes." },
    { animationData: clientFocusAnimation, title: "Client-Focused Philosophy", description: "Our clients' needs guide our efforts. We craft solutions that exceed their expectations." },
    { animationData: growthAnimation, title: "Continuous Growth", description: "We embrace change and continually seek better methods to meet evolving needs." },
  ];

  return (
    <Container maxWidth="lg" className="values-container">
      <Typography variant="h4" align="center" gutterBottom className="values-header">
        Our Core Values
      </Typography>
      <Grid container spacing={4}>
        {valueItems.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ValueItem {...item} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Values;