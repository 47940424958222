import React from 'react';
import IndustryTemplate from './IndustryTemplate';
import { Container, Typography, Box, Chip } from '@mui/material';

function Healthcare() {
  const title = "Healthcare & Life Sciences Solutions";
  const description = "ARQNXS provides innovative AI and data analytics solutions for the healthcare and life sciences industries, improving patient care, accelerating drug discovery, and optimizing clinical trials.";
  const solutions = [
    { 
      title: "Predictive Diagnostics", 
      description: "Utilize AI to predict potential health issues and improve early diagnosis.",
      useCases: [
        "Early detection of diseases using medical imaging",
        "Risk assessment for chronic conditions",
        "Personalized treatment recommendations"
      ],
      technologies: ["Deep Learning", "Computer Vision", "Natural Language Processing"]
    },
    { 
      title: "Drug Discovery Acceleration", 
      description: "Employ machine learning to speed up the drug discovery and development process.",
      useCases: [
        "Virtual screening of drug candidates",
        "Protein structure prediction",
        "Toxicity prediction and side effect analysis"
      ],
      technologies: ["Molecular Dynamics Simulations", "Reinforcement Learning", "Quantum Computing"]
    },
    { 
      title: "Patient Data Management", 
      description: "Implement secure and efficient systems for managing and analyzing patient data.",
      useCases: [
        "Electronic Health Records (EHR) optimization",
        "Predictive analytics for patient outcomes",
        "Secure data sharing and interoperability"
      ],
      technologies: ["Blockchain", "Federated Learning", "HIPAA-compliant Cloud Solutions"]
    },
  ];

  const benefits = [
    "Improved patient outcomes",
    "Accelerated drug discovery and development",
    "Enhanced operational efficiency in healthcare",
    "Better resource allocation and cost reduction",
    "Increased data security and privacy"
  ];

  return (
    <IndustryTemplate title={title} description={description} solutions={solutions}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>Key Benefits</Typography>
        <Box display="flex" flexWrap="wrap" gap={1} mb={4}>
          {benefits.map((benefit, index) => (
            <Chip key={index} label={benefit} color="primary" />
          ))}
        </Box>
        <Typography variant="h4" gutterBottom>Our Commitment</Typography>
        <Typography variant="body1" paragraph>
          ARQNXS is dedicated to advancing healthcare and life sciences through innovative AI solutions. We work closely with healthcare providers, pharmaceutical companies, and research institutions to develop cutting-edge technologies that address critical challenges in the industry. Our solutions are designed to improve patient care, accelerate scientific discoveries, and ultimately save lives.
        </Typography>
      </Container>
    </IndustryTemplate>
  );
}

export default Healthcare;