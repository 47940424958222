// src/pages/services/WebDevelopment.js
import React from 'react';
import ServicePage from './ServicePage';
import { CodeRounded, SpeedRounded, DevicesRounded, CloudRounded, Security, TrendingUp } from '@mui/icons-material';

function WebDevelopment() {
  const features = [
    { 
      title: 'Custom Web Applications', 
      description: 'Tailored web solutions to meet your specific business needs.',
      icon: <CodeRounded />
    },
    { 
      title: 'Responsive Design', 
      description: 'Ensure your web application looks great on all devices.',
      icon: <DevicesRounded />
    },
    { 
      title: 'Performance Optimization', 
      description: 'Fast, efficient web applications for the best user experience.',
      icon: <SpeedRounded />
    },
  ];

  const benefits = [
    { title: 'Improved Online Presence', description: 'Stand out with a professional, modern web presence.', icon: <TrendingUp /> },
    { title: 'Scalability', description: 'Build solutions that grow with your business.', icon: <CloudRounded /> },
    { title: 'Enhanced Security', description: 'Protect your data and users with robust security measures.', icon: <Security /> },
  ];

  const useCases = [
    'E-commerce platforms and online marketplaces',
    'Content management systems (CMS) and blogs',
    'Customer portals and self-service platforms',
    'Enterprise resource planning (ERP) systems',
    'Software as a Service (SaaS) applications',
  ];

  const content = `
    <h2>Elevate Your Digital Presence with Custom Web Development</h2>
    <p>At ARQNXS, we specialize in creating powerful, scalable, and user-friendly web applications that drive business growth and enhance user engagement. Our team of experienced web developers combines technical expertise with creative problem-solving to deliver web solutions that exceed expectations.</p>
    
    <h3>Our Web Development Approach</h3>
    <ol>
      <li><strong>Requirements Gathering:</strong> We work closely with you to understand your business goals and user needs.</li>
      <li><strong>Planning and Architecture:</strong> We design a robust architecture tailored to your specific requirements.</li>
      <li><strong>UI/UX Design:</strong> Our designers create intuitive, visually appealing interfaces for optimal user experience.</li>
      <li><strong>Development:</strong> We build your web application using modern technologies and best practices.</li>
      <li><strong>Testing and Quality Assurance:</strong> We rigorously test your application to ensure reliability and performance.</li>
      <li><strong>Deployment and Maintenance:</strong> We handle deployment and provide ongoing support and updates.</li>
    </ol>

    <h3>Technologies We Leverage</h3>
    <ul>
      <li>React, Angular, and Vue.js for front-end development</li>
      <li>Node.js, Python, and PHP for back-end development</li>
      <li>AWS, Google Cloud, and Azure for cloud hosting and services</li>
      <li>GraphQL and REST for API development</li>
      <li>MongoDB, PostgreSQL, and MySQL for database management</li>
    </ul>
  `;

  return (
    <ServicePage
      title="Web Development"
      description="Cutting-edge web solutions to drive your business forward in the digital age."
      icon={<CodeRounded />}
      features={features}
      benefits={benefits}
      useCases={useCases}
      content={content}
      callToAction="Ready to transform your web presence? Let's create your custom web solution."
    />
  );
}

export default WebDevelopment;