// src/pages/industries/IndustryTemplate.js
import React from 'react';
import { Container, Typography, Paper, Box, Chip } from '@mui/material';
import './IndustryTemplate.css';

function IndustryTemplate({ title, description, solutions, children }) {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h2" gutterBottom>{title}</Typography>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="body1">{description}</Typography>
      </Paper>
      <div className="solutions-grid">
        {solutions.map((solution, index) => (
          <Paper key={index} elevation={3} className="solution-paper">
            <Typography variant="h5" className="solution-title">{solution.title}</Typography>
            <Typography variant="body2" paragraph>{solution.description}</Typography>
            {solution.useCases && (
              <>
                <Typography variant="subtitle1" gutterBottom>Use Cases:</Typography>
                <ul>
                  {solution.useCases.map((useCase, i) => (
                    <li key={i}><Typography variant="body2">{useCase}</Typography></li>
                  ))}
                </ul>
              </>
            )}
            {solution.technologies && (
              <>
                <Typography variant="subtitle1" gutterBottom>Technologies:</Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {solution.technologies.map((tech, i) => (
                    <Chip key={i} label={tech} size="small" className="tech-chip" />
                  ))}
                </Box>
              </>
            )}
          </Paper>
        ))}
      </div>
      <Box mt={6}>
        {children}
      </Box>
    </Container>
  );
}

export default IndustryTemplate;