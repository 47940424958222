// src/pages/AIMLEngineering.js
import React from 'react';
import { Container, Typography, Grid, Paper, Box, Button } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import './AIMLEngineering.css';

// Import your Lottie JSON files
import aiModelAnimation from './icons/ai-model.json';
import mlIntegrationAnimation from './icons/ml-integration.json';
import dataAnalyticsAnimation from './icons/data-analytics.json';

function AIMLEngineering() {
  const aimlServices = [
    { title: 'Custom AI Model Development', description: 'Tailored AI solutions to meet your specific business needs.', animation: aiModelAnimation, link: '/services/ai-ml' },
    { title: 'Machine Learning Integration', description: 'Seamlessly integrate ML capabilities into your existing systems.', animation: mlIntegrationAnimation, link: '/services/ai-ml' },
    { title: 'Data Analytics & Insights', description: 'Extract valuable insights from your data using advanced analytics.', animation: dataAnalyticsAnimation, link: '/services/data-analytics' },
  ];

  return (
    <div className="aiml-engineering">
      <Container maxWidth="lg">
        <Typography variant="h2" gutterBottom className="page-title">
          AI/ML Engineering
        </Typography>
        <Typography variant="h5" paragraph className="page-subtitle">
          Cutting-edge artificial intelligence and machine learning solutions to drive your business forward.
        </Typography>

        <Grid container spacing={4}>
          {aimlServices.map((service, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Paper elevation={3} className="service-card">
                <Box className="service-icon">
                  <Lottie animationData={service.animation} loop={true} />
                </Box>
                <Typography variant="h6" gutterBottom>{service.title}</Typography>
                <Typography variant="body2">{service.description}</Typography>
                <Button component={Link} to={service.link} endIcon={<ArrowForward />} sx={{ mt: 2 }}>
                  Learn More
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Box mt={6}>
          <Typography variant="h4" gutterBottom>Our AI/ML Expertise</Typography>
          <Typography variant="body1" paragraph>
            At ARQNXS, we leverage the latest advancements in artificial intelligence and machine learning to solve complex business challenges. Our team of expert data scientists and AI engineers work closely with you to understand your unique needs and develop custom solutions that drive real business value.
          </Typography>
          <Typography variant="body1" paragraph>
            Whether you're looking to implement predictive analytics, automate processes with machine learning, or develop cutting-edge AI applications, we have the skills and experience to bring your vision to life.
          </Typography>
          <Button component={Link} to="/services/ai-ml" variant="contained" color="primary" endIcon={<ArrowForward />}>
            Explore Our AI/ML Services
          </Button>
        </Box>
      </Container>
    </div>
  );
}

export default AIMLEngineering;