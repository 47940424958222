import React from 'react';
import { Container, Typography, Box} from '@mui/material';
import { Psychology, DesignServices, DeveloperMode, CloudUpload, Analytics, Support } from '@mui/icons-material';
import { motion, useScroll, useTransform } from 'framer-motion';
import './TechProcess.css';

const TechProcess = () => {
 

  const { scrollYProgress } = useScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [0.8, 1]);

  const steps = [
    {
      title: '1. Consultation & Analysis',
      description: 'We begin by deeply understanding your business needs, challenges, and goals. Our team conducts thorough market research and data analysis to inform our strategy.',
      tools: 'Python, Pandas, NumPy, Matplotlib, Jupyter Notebooks',
      icon: <Psychology />,
      color: '#FF6B6B'
    },
    {
      title: '2. Strategy & Design',
      description: 'We develop a tailored strategy and design comprehensive solutions that address your unique requirements, leveraging our expertise in AI, ML, and FinTech.',
      tools: 'TensorFlow, PyTorch, Solidity, Agile Methodologies',
      icon: <DesignServices />,
      color: '#4ECDC4'
    },
    {
      title: '3. Full-Stack Development',
      description: 'Our skilled team executes the strategy with precision, developing robust, scalable solutions using cutting-edge technologies across the full stack.',
      tools: 'JavaScript, ReactJS, MEAN Stack, Python, Flask, RESTful APIs, GraphQL, Docker, Kubernetes',
      icon: <DeveloperMode />,
      color: '#45B7D1'
    },
    {
      title: '4. Deployment & Integration',
      description: 'We ensure smooth deployment and seamless integration of our solutions into your existing infrastructure, following best practices in DevOps and cloud technologies.',
      tools: 'AWS, Azure, CI/CD, Docker, Kubernetes, Microservices',
      icon: <CloudUpload />,
      color: '#F9C80E'
    },
    {
      title: '5. Testing & Optimization',
      description: 'We rigorously test and optimize our solutions, ensuring high performance, security, and scalability. Our data-driven approach allows for continuous improvements.',
      tools: 'Test-Driven Development (TDD), Data Analysis, Business Intelligence (BI), Statistical Analysis',
      icon: <Analytics />,
      color: '#FF8C42'
    },
    {
      title: '6. Ongoing Support & Innovation',
      description: 'We provide continuous support and stay at the forefront of technological advancements to ensure your solution remains cutting-edge and adapts to evolving business needs.',
      tools: 'AI/ML Algorithms, Natural Language Processing (NLP), Computer Vision, Blockchain Development',
      icon: <Support />,
      color: '#98CE00'
    }
  ];

  return (
    <section className="tech-process">
      <Container maxWidth="lg">
        <Box className="header-container">
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <Typography variant="h2" gutterBottom className="section-title">
              <motion.span
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2, duration: 0.8 }}
              >
                Our
              </motion.span>{" "}
              <motion.span
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.4, duration: 0.8 }}
                className="highlight"
              >
                Comprehensive
              </motion.span>{" "}
              <motion.span
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.6, duration: 0.8 }}
              >
                Approach
              </motion.span>
            </Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8, duration: 0.8, ease: "easeOut" }}
          >
            <Typography variant="body1" paragraph align="center" className="section-subtitle">
              At ARQNXS, we take pride in our end-to-end solution delivery, 
              <br />from initial consultation to full-stack implementation.
            </Typography>
          </motion.div>
          <motion.div
            className="decorative-line"
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            transition={{ delay: 1, duration: 1, ease: "easeInOut" }}
          />
        </Box>
        <motion.div className="process-timeline" style={{ scale }}>
          {steps.map((step, index) => (
            <motion.div
              key={index}
              className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`}
              initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 0.8, ease: "easeOut", delay: index * 0.2 }}
            >
              <div className="timeline-content" style={{ borderTop: `5px solid ${step.color}` }}>
                <motion.div 
                  className="timeline-icon"
                  style={{ backgroundColor: step.color }}
                  whileHover={{ scale: 1.2, rotate: 360 }}
                  transition={{ duration: 0.3 }}
                >
                  {step.icon}
                </motion.div>
                <Typography variant="h6" className="step-title">{step.title}</Typography>
                <Typography className="step-description">{step.description}</Typography>
                <Box className="tools-container">
                  {step.tools.split(', ').map((tool, toolIndex) => (
                    <motion.span 
                      key={toolIndex} 
                      className="tool-chip"
                      whileHover={{ scale: 1.1 }}
                      transition={{ duration: 0.2 }}
                    >
                      {tool}
                    </motion.span>
                  ))}
                </Box>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </Container>
    </section>
  );
};

export default TechProcess;