// src/pages/services/AIML.js
import React from 'react';
import ServicePage from './ServicePage';
import { PsychologyRounded, IntegrationInstructionsRounded, TranslateRounded, Speed, TrendingUp, Security } from '@mui/icons-material';

function AIML() {
  const features = [
    { 
      title: 'Custom AI Models', 
      description: 'Develop tailored AI solutions to address your unique challenges.',
      icon: <PsychologyRounded />
    },
    { 
      title: 'Machine Learning Integration', 
      description: 'Seamlessly incorporate ML capabilities into your existing systems.',
      icon: <IntegrationInstructionsRounded />
    },
    { 
      title: 'Natural Language Processing', 
      description: 'Implement advanced NLP for improved communication and data analysis.',
      icon: <TranslateRounded />
    },
  ];

  const benefits = [
    { title: 'Increased Efficiency', description: 'Automate complex tasks and streamline operations.', icon: <Speed /> },
    { title: 'Data-Driven Insights', description: 'Uncover hidden patterns and predict future trends.', icon: <TrendingUp /> },
    { title: 'Enhanced Decision Making', description: 'Make informed decisions based on AI-powered analytics.', icon: <Security /> },
  ];

  const useCases = [
    'Predictive maintenance in manufacturing',
    'Personalized recommendations in e-commerce',
    'Fraud detection in financial services',
    'Automated customer support chatbots',
    'Image and speech recognition systems',
  ];

  const content = `
    <h2>Transforming Businesses with AI and Machine Learning</h2>
    <p>At ARQNXS, we harness the power of Artificial Intelligence and Machine Learning to drive innovation and efficiency across industries. Our team of expert data scientists and AI engineers work closely with you to understand your unique challenges and develop custom solutions that deliver tangible business value.</p>
    
    <h3>Our Approach</h3>
    <p>We follow a comprehensive approach to AI and ML implementation:</p>
    <ol>
      <li><strong>Assessment:</strong> We evaluate your current systems and identify opportunities for AI/ML integration.</li>
      <li><strong>Strategy:</strong> We develop a tailored AI/ML strategy aligned with your business goals.</li>
      <li><strong>Development:</strong> Our team builds and trains custom AI models using cutting-edge techniques.</li>
      <li><strong>Integration:</strong> We seamlessly integrate AI/ML solutions into your existing infrastructure.</li>
      <li><strong>Monitoring & Optimization:</strong> We continuously monitor and refine the models to ensure optimal performance.</li>
    </ol>

    <h3>Technologies We Use</h3>
    <p>Our AI and ML solutions leverage state-of-the-art technologies and frameworks, including:</p>
    <ul>
      <li>TensorFlow and PyTorch for deep learning</li>
      <li>Scikit-learn for traditional machine learning algorithms</li>
      <li>Apache Spark for big data processing</li>
      <li>NVIDIA CUDA for GPU acceleration</li>
      <li>Cloud-based AI services (AWS SageMaker, Google Cloud AI, Azure Machine Learning)</li>
    </ul>
  `;

  return (
    <ServicePage
      title="AI and Machine Learning"
      description="Leverage the power of artificial intelligence and machine learning to drive innovation and efficiency."
      icon={<PsychologyRounded />}
      features={features}
      benefits={benefits}
      useCases={useCases}
      content={content}
      callToAction="Ready to harness the power of AI? Let's discuss your project."
    />
  );
}

export default AIML;