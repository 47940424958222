// src/pages/FinTechInnovation.js
import React from 'react';
import { Container, Typography, Grid, Paper, Box, Button } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import './FinTechInnovation.css';

// Import your Lottie JSON files
import blockchainAnimation from './icons/blockchain.json';
import digitalBankingAnimation from './icons/digital-banking.json';
import regulatoryComplianceAnimation from './icons/regulatory-compliance.json';

function FinTechInnovation() {
  const fintechServices = [
    { title: 'Blockchain Solutions', description: 'Secure and transparent blockchain implementations for financial services.', animation: blockchainAnimation, link: '/services/blockchain' },
    { title: 'Digital Banking Platforms', description: 'Modern, user-friendly digital banking solutions.', animation: digitalBankingAnimation, link: '/services/web-development' },
    { title: 'Regulatory Compliance Tech', description: 'Stay compliant with evolving financial regulations using our advanced tech.', animation: regulatoryComplianceAnimation, link: '/services/data-analytics' },
  ];

  return (
    <div className="fintech-innovation">
      <Container maxWidth="lg">
        <Typography variant="h2" gutterBottom className="page-title">
          FinTech Innovation
        </Typography>
        <Typography variant="h5" paragraph className="page-subtitle">
          Revolutionary technology-driven solutions for the financial sector.
        </Typography>

        <Grid container spacing={4}>
          {fintechServices.map((service, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Paper elevation={3} className="service-card">
                <Box className="service-icon">
                  <Lottie animationData={service.animation} loop={true} />
                </Box>
                <Typography variant="h6" gutterBottom>{service.title}</Typography>
                <Typography variant="body2">{service.description}</Typography>
                <Button component={Link} to={service.link} endIcon={<ArrowForward />} sx={{ mt: 2 }}>
                  Learn More
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Box mt={6}>
          <Typography variant="h4" gutterBottom>Transforming Finance with Technology</Typography>
          <Typography variant="body1" paragraph>
            ARQNXS is at the forefront of the FinTech revolution, combining our deep understanding of the financial sector with cutting-edge technology solutions. We help financial institutions, from startups to established banks, leverage the power of technology to enhance their services, improve operational efficiency, and stay ahead in a rapidly evolving market.
          </Typography>
          <Typography variant="body1" paragraph>
            Our FinTech solutions encompass a wide range of services, including blockchain implementation, AI-driven risk assessment, mobile banking applications, and more. We're committed to helping our clients navigate the complex landscape of financial technology and regulatory compliance.
          </Typography>
          <Button component={Link} to="/industries/fintech" variant="contained" color="primary" endIcon={<ArrowForward />}>
            Explore Our FinTech Solutions
          </Button>
        </Box>
      </Container>
    </div>
  );
}

export default FinTechInnovation;