// src/components/GrowthMetrics.js
import React from 'react';
import { Container, Typography, Grid, Paper } from '@mui/material';
import Lottie from 'lottie-react';
import './GrowthMetrics.css';

// Import your Lottie JSON files
import projectsAnimation from './icons/projects.json';
import satisfactionAnimation from './icons/satisfaction.json';
import innovationAnimation from './icons/innovation.json';
import industriesAnimation from './icons/industries.json';

const metrics = [
  { value: '10+', label: 'Major Projects Completed', animation: projectsAnimation },
  { value: '95%', label: 'Client Satisfaction', animation: satisfactionAnimation },
  { value: '6+', label: 'Years of Innovation', animation: innovationAnimation },
  { value: '5+', label: 'Industries Served', animation: industriesAnimation }
];

const MetricCard = ({ metric, index }) => (
  <Grid item xs={12} sm={6} md={3} data-aos="zoom-in" data-aos-delay={index * 100}>
    <Paper elevation={3} className="metric-paper">
      <div className="metric-icon">
        <Lottie animationData={metric.animation} loop={true} />
      </div>
      <Typography variant="h3" className="metric-value">
        {metric.value}
      </Typography>
      <Typography variant="h6" className="metric-label">
        {metric.label}
      </Typography>
    </Paper>
  </Grid>
);

const GrowthMetrics = () => {
  return (
    <section className="growth-metrics">
      <Container maxWidth="lg">
        <Typography variant="h2" gutterBottom className="section-title" data-aos="fade-up">
          Our Growth & Impact
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {metrics.map((metric, index) => (
            <MetricCard key={index} metric={metric} index={index} />
          ))}
        </Grid>
      </Container>
    </section>
  );
};

export default GrowthMetrics;