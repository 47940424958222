import React from 'react';
import IndustryTemplate from './IndustryTemplate';
import { Container, Typography, Box, Chip } from '@mui/material';

function Advertisement() {
  const title = "Advertisement Solutions";
  const description = "ARQNXS revolutionizes the advertising industry with AI-powered solutions that optimize ad targeting, improve campaign performance, and maximize ROI.";
  const solutions = [
    { 
      title: "AI-Driven Ad Targeting", 
      description: "Leverage machine learning for precise audience targeting and improved ad relevance.",
      useCases: [
        "Behavioral targeting based on user browsing history",
        "Contextual advertising using natural language processing",
        "Predictive modeling for audience segmentation"
      ],
      technologies: ["Machine Learning", "Natural Language Processing", "Big Data Analytics"]
    },
    { 
      title: "Real-time Campaign Optimization", 
      description: "Implement AI algorithms for real-time adjustment of ad campaigns based on performance data.",
      useCases: [
        "Dynamic bid optimization in programmatic advertising",
        "Automated A/B testing for ad creatives",
        "Real-time budget allocation across channels"
      ],
      technologies: ["Real-time Data Processing", "Machine Learning", "Predictive Analytics"]
    },
    { 
      title: "Cross-Channel Attribution", 
      description: "Utilize advanced analytics to accurately attribute conversions across multiple marketing channels.",
      useCases: [
        "Multi-touch attribution modeling",
        "Customer journey analysis",
        "ROI optimization across channels"
      ],
      technologies: ["Data Visualization", "Statistical Modeling", "Machine Learning"]
    },
  ];

  const benefits = [
    "Increased ad relevance and engagement",
    "Improved ROI on advertising spend",
    "Enhanced customer insights and segmentation",
    "More efficient budget allocation",
    "Better understanding of customer journey"
  ];

  return (
    <IndustryTemplate title={title} description={description} solutions={solutions}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>Key Benefits</Typography>
        <Box display="flex" flexWrap="wrap" gap={1} mb={4}>
          {benefits.map((benefit, index) => (
            <Chip key={index} label={benefit} color="primary" />
          ))}
        </Box>
        <Typography variant="h4" gutterBottom>Our Approach</Typography>
        <Typography variant="body1" paragraph>
          At ARQNXS, we combine cutting-edge AI technologies with deep industry expertise to deliver advertising solutions that drive real business results. Our team of data scientists and marketing experts work closely with clients to understand their unique challenges and develop tailored solutions that leverage the power of AI and machine learning.
        </Typography>
      </Container>
    </IndustryTemplate>
  );
}

export default Advertisement;