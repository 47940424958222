import React from 'react';
import { Container, Typography, Grid, Paper, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './Solutions.css';

// Import images
import fintech from './icons/FinTech.png';
import aiml from './icons/AIML.png';
import research from './icons/Research.png';

const solutions = [
  {
    title: "AI/ML Engineering",
    description: "Leverage cutting-edge artificial intelligence and machine learning solutions to drive innovation and efficiency in your business processes.",
    icon: aiml,
    color: "#002fffc2",
    features: [
      "Custom AI model development",
      "Machine learning integration",
      "Natural language processing",
      "Computer vision solutions"
    ],
    link: "/ai-ml-engineering"
  },
  {
    title: "FinTech Innovation",
    description: "Revolutionize financial services with our advanced FinTech solutions, combining security, efficiency, and user-centric design.",
    icon: fintech,
    color: "#002fffc2",
    features: [
      "Blockchain implementations",
      "Digital banking platforms",
      "AI-driven fraud detection",
      "Automated trading systems"
    ],
    link: "/fintech-innovation"
  },
  {
    title: "R&D",
    description: "Stay ahead of the curve with our forward-thinking R&D initiatives, exploring emerging technologies and their practical applications.",
    icon: research,
    color: "#002fffc2",
    features: [
      "Deep Learning research",
      "Advanced theoretical exploration",
      "Sustainable tech solutions",
      "Next-gen IoT ecosystems"
    ],
    link: "/research-development"
  }
];
const SolutionCard = ({ solution, index }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false, // Changed to false to allow repeated animations
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        delay: index * 0.2,
      },
    },
  };

  const iconVariants = {
    hidden: { scale: 0 },
    visible: {
      scale: 1,
      transition: {
        type: 'spring',
        stiffness: 260,
        damping: 20,
        delay: index * 0.2 + 0.3,
      },
    },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={cardVariants}
    >
      <Paper elevation={3} className="solution-card" style={{ borderTop: `4px solid ${solution.color}` }}>
        <motion.div className="solution-icon" variants={iconVariants}>
          <img src={solution.icon} alt={solution.title} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        </motion.div>
        <Typography variant="h4" gutterBottom className="solution-title">
          {solution.title}
        </Typography>
        <Typography variant="body1" className="solution-description">
          {solution.description}
        </Typography>
        <Box className="solution-features">
          <Typography variant="h6" gutterBottom>Key Features:</Typography>
          <ul>
            {solution.features.map((feature, idx) => (
              <motion.li
                key={idx}
                variants={{
                  hidden: { opacity: 0, x: -20 },
                  visible: { 
                    opacity: 1, 
                    x: 0,
                    transition: { delay: index * 0.2 + 0.5 + idx * 0.1 }
                  }
                }}
              >
                {feature}
              </motion.li>
            ))}
          </ul>
        </Box>
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <Button
            component={Link}
            to={solution.link}
            variant="contained"
            color="primary"
            className="solution-button"
          >
            Learn More
          </Button>
        </motion.div>
      </Paper>
    </motion.div>
  );
};

const Solutions = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false, // Changed to false to allow repeated animations
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const titleVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        when: 'beforeChildren',
      },
    },
  };

  return (
    <section className="solutions" ref={ref}>
      <Container maxWidth="lg">
        <motion.div
          initial="hidden"
          animate={controls}
          variants={titleVariants}
        >
          <Typography variant="h2" gutterBottom className="section-title">
            Our Core Solutions
          </Typography>
        </motion.div>
        <Grid container spacing={4}>
          {solutions.map((solution, index) => (
            <Grid item xs={12} md={4} key={index}>
              <SolutionCard solution={solution} index={index} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
};

export default Solutions;