// src/pages/Home.js
import React, { useEffect }  from 'react';
import { Link } from 'react-router-dom';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Home.css';

import TechStackCarousel from './TechCarousel';
import TechExpertiseSection from './TechExpertiseSection';  
import TechProcess from './TechProcess';
import Values from './Values'; 
import Solutions from './Solutions';
import GrowthMetrics from './GrowthMetrics';
import Achievements from './Achievements';
import CaseStudies from './CaseStudies';
import PartnersSection from './PartnersSection';
import HeroSection from './HeroSection';


import AOS from 'aos';

// Material-UI Components
import { 
  Container, 
  Typography, 
  Grid, 
  Paper, 
  Button, 
  Box, 
  Card, 
  CardContent, 
  
} from '@mui/material';

// Material-UI Icons
import { 

  

  FormatQuote, 
  
} from '@mui/icons-material';

function Home() {
  
  const testimonials = [
    { name: "Ganesh Ranganathan (CEO)", company: "AI Project Client", quote: "ARQNXS is a capable, creative, and efficient AI company. ARQNXS is able to exceed client expectations, expanding the horizons of a project." },
    { name: "M. Quintera (CEO)", company: "Quantic Eagle Ltd.", quote: "Nigel is a capable, creative, and efficient individual. He is able to exceed client expectations, expanding the horizons of a project." },
    { name: "Shaheryar Tariq Khan (CEO)", company: "TAK Devs", quote: "Nigel, founder of ARQNXS, is a tech-savvy and broad-minded CTO, who welcomes change and motivates to do better every day. He is highly productive, organized, and has good communication skills." },
    { name: "J. K. (CEO)", company: "US Defense Contractor (Confidential)", quote: "Our team needed Nigel's expertise to assess and analyze a highly complex data set to assist us in business development and project management optimization. Nigel completed the project on time and beyond expectations. We are preparing to work with him now to integrate machine learning processes into our workflow. We give Nigel our highest recommendation to anyone interested, and we look forward to continued work with him for the foreseeable future."
 },

  ];

  const news = [
    { title: "Partnership Announcement with TotalMarketeer", date: "2024-07-06", excerpt: "We're excited to announce partnering with TotalMarketeer a global AdTech company..." },
    { title: "ARQNXS Launches Compliance Checker: EU AI Act", date: "2024-07-13", excerpt: "Our latest tool enables any business to check compliance under the EU AI Act..." },
    { title: "Partnership Announcement with TAKdevs", date: "2024-07-20", excerpt: "We're excited to announce our strategic partnership with full-stack webtech company TAKdevs..." },
    { title: "ARQNXS Launches Zero-Tier Tokenomics Tool", date: "2024-07-17", excerpt: "ARQNXS developed a Tokenomics Tool incorporating the latest FinTech solutions..." },
  ];

 
 
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };


  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);


  return (
    <div className="home">
    
    <HeroSection />

      <Solutions />
      
     

      <Values />

      <GrowthMetrics />


     <TechProcess />
   


      <TechExpertiseSection />

      <CaseStudies />

     
      <section className="testimonials">
        <Container maxWidth="lg">
          <Typography variant="h2" gutterBottom className="section-title">
            What Our Clients Say
          </Typography>
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div key={index}>
                <Card className="testimonial-card">
                  <CardContent>
                    <FormatQuote className="quote-icon" />
                    <Typography variant="body1" paragraph>
                      "{testimonial.quote}"
                    </Typography>
                    <Typography variant="h6">{testimonial.name}</Typography>
                    <Typography variant="subtitle1">{testimonial.company}</Typography>
                  </CardContent>
                </Card>
              </div>
            ))}
          </Slider>
        </Container>
      </section>
      
      
      <Achievements />
      
      

      {/* Tech Stack Carousel Section */}
        <TechStackCarousel />

        <section className="latest-news">
        <Container maxWidth="lg">
          <Typography variant="h2" gutterBottom className="section-title">
            Latest News
          </Typography>
          <Grid container spacing={4}>
            {news.slice(0, 3).map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card className="news-card">
                  <CardContent>
                    <Typography variant="h6" gutterBottom>{item.title}</Typography>
                    <Typography variant="subtitle2" gutterBottom>{item.date}</Typography>
                    <Typography variant="body2">{item.excerpt}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Box mt={4} textAlign="center">
            <Button component={Link} to="/news" variant="contained" color="primary">
              View All News
            </Button>
          </Box>
        </Container>
      </section>

    


      <PartnersSection />



      <section className="cta">
        <Container maxWidth="md">
          <Paper elevation={3} className="cta-paper">
            <Typography variant="h4" gutterBottom>
              Ready to Transform Your Business?
            </Typography>
            <Typography variant="body1" paragraph>
              Let's collaborate to bring your innovative ideas to life.
            </Typography>
            <Button variant="contained" color="secondary" component={Link} to="/contact" size="large">
              Get Started Now
            </Button>
          </Paper>
        </Container>
      </section>
    </div>
  );
}

export default Home;