import React from 'react';
import { Container, Typography, Grid, Paper } from '@mui/material';
import Lottie from 'lottie-react';
import './Achievements.css';

// Import Lottie animations
import educationAnimation from './icons/education.json';
import procurementAnimation from './icons/procurement.json';
import chatbotAnimation from './icons/chatbot.json';
import tradingAnimation from './icons/trading.json';
import globalDataAnimation from './icons/global-data.json';
import bigDataAnimation from './icons/big-data.json';

const Achievement = ({ title, description, animationData }) => (
  <Paper elevation={3} className="achievement-paper">
    <div className="achievement-icon">
      <Lottie 
        animationData={animationData}
        loop={true}
        autoplay={true}
        style={{ width: 80, height: 80 }}
        className="animated-icon"
      />
    </div>
    <Typography variant="h6" className="achievement-title">{title}</Typography>
    <Typography variant="body2" className="achievement-description">{description}</Typography>
  </Paper>
);

const Achievements = () => {
  const achievements = [
    {
      title: "AI-Powered Education",
      description: "Developed an AI solution capable of answering 95% of MCQs accurately, revolutionizing self-learning for students.",
      animationData: educationAnimation
    },
    {
      title: "Procurement Efficiency Boost",
      description: "Increased procurement search flow by 800% with our AI-powered platform, integrating 22+ sources and advanced filters.",
      animationData: procurementAnimation
    },
    {
      title: "High-Performance AI Chatbot",
      description: "Developed an ML-driven AI Chatbot capable of handling 1 million requests per minute with real-time sentiment analysis.",
      animationData: chatbotAnimation
    },
    {
      title: "Advanced Trading Solutions",
      description: "Engineered \"TradeBrain\", an intelligent trading app with sophisticated features for enhanced trade management and analysis.",
      animationData: tradingAnimation
    },
    {
      title: "Global Data Integration",
      description: "Implemented real-time NORAD satellite tracking and created dynamic mapping tools for global procurement optimization.",
      animationData: globalDataAnimation
    },
    {
      title: "Big Data Expertise",
      description: "Developed advanced web scrapers and implemented Elastic Search Cloud for efficient handling and analysis of large-scale data.",
      animationData: bigDataAnimation
    }
  ];

  return (
    <section className="achievements">
      <Container maxWidth="lg">
        <Typography variant="h2" gutterBottom className="section-title">
          Our Achievements
        </Typography>
        <Grid container spacing={4}>
          {achievements.map((achievement, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Achievement {...achievement} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
};

export default Achievements;