// In your App.js or a separate theme file

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#002fffc2', // Deep blue #002fffc2
      light: '#6573ff',
      dark: '#0021c0',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#045536', // Dark green
      light: '#3a8161',
      dark: '#002c0d',
      contrastText: '#ffffff',
    },
    background: {
      default: '#f8f9fa',
      paper: '#ffffff',
    },
    text: {
      primary: '#333333',
      secondary: '#666666',
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", "Helvetica Neue", "Arial", sans-serif',
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    button: {
      textTransform: 'none', // This prevents automatic uppercase transformation
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px', // Slightly rounded corners for buttons
        },
        containedPrimary: {
          background: 'linear-gradient(45deg, #2148f3 30%, #21a0f3 90%)',
          '&:hover': {
            background: 'linear-gradient(45deg, #1a3ac2 30%, #1a80c2 90%)',
          },
        },
        containedSecondary: {
          background: 'linear-gradient(45deg, #045536 30%, #0a8f5c 90%)',
          '&:hover': {
            background: 'linear-gradient(45deg, #034429 30%, #087a4f 90%)',
          },
        },
      },
    },
  },
});

export default theme;