// src/pages/services/ServicePage.js
import React from 'react';
import { Container, Typography, Grid, Paper, Box, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import './ServicePage.css';
import { Link } from 'react-router-dom';

function ServicePage({ title, description, icon, features, benefits, useCases, content, callToAction }) {
  return (
    <div className="service-page">
      <Container maxWidth="lg">
        <Box display="flex" alignItems="center" mb={4}>
          {icon && <Box mr={2} fontSize="3rem">{icon}</Box>}
          <Typography variant="h2" className="page-title">
            {title}
          </Typography>
        </Box>
        <Typography variant="body1" className="page-subtitle">
          {description}
        </Typography>

        <Grid container spacing={4} mb={6}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Paper elevation={3} className="feature-card">
                <Box className="feature-icon">{feature.icon}</Box>
                <Typography variant="h6" gutterBottom>{feature.title}</Typography>
                <Typography variant="body2">{feature.description}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>

        {benefits && (
          <Box mb={6}>
            <Typography variant="h4" gutterBottom>Key Benefits</Typography>
            <Grid container spacing={3}>
              {benefits.map((benefit, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Paper elevation={2} className="benefit-card">
                    <Box className="benefit-icon">{benefit.icon}</Box>
                    <Typography variant="h6" gutterBottom>{benefit.title}</Typography>
                    <Typography variant="body2">{benefit.description}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {useCases && (
          <Box mb={6}>
            <Typography variant="h4" gutterBottom>Use Cases</Typography>
            <List>
              {useCases.map((useCase, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleOutline color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={useCase} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {content && (
          <Box mb={6} className="service-content">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Box>
        )}

      {callToAction && (
            <Box textAlign="center" mt={6}>
                <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                >
                {callToAction}
                </Button>
            </Box>
            )}

      </Container>
    </div>
  );
}

export default ServicePage;