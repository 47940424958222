import React from 'react';
import { Container, Typography, Grid, Link } from '@mui/material';

import takdevs from '../pages/partnerlogos/takdevs.png'

import nato from '../pages/partnerlogos/nato.png'


const partners = [
  { name: 'NATO', logo: nato, website: 'https://eportal.nspa.nato.int/Codification/CageTool/cage-view/H2R48'},
  { name: 'TakDevs', logo: takdevs, website: 'https://takdevs.com' }
  
];

const PartnersSection = () => {
  return (
    <section className="partners">
      <Container maxWidth="lg">
        <Typography variant="h2" gutterBottom className="section-title">
          Our Partners
        </Typography>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          {partners.map((partner, index) => (
            <Grid item key={index}>
              <Link 
                href={partner.website} 
                target="_blank" 
                rel="noopener noreferrer"
                sx={{
                  display: 'block',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)'
                  }
                }}
              >
                <img 
                  src={partner.logo} 
                  alt={partner.name} 
                  className="partner-logo" 
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
};

export default PartnersSection;