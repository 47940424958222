import React from 'react';
import { Box, Container, Typography, Grid, Paper, Chip, IconButton } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Code, Web, Psychology, Cloud, Storage, Devices } from '@mui/icons-material';
import './TechExpertiseSection.css';

const categoryIcons = {
  "Programming Languages": <Code />,
  "Web & Mobile Development": <Web />,
  "AI & Machine Learning": <Psychology />,
  "Cloud & DevOps": <Cloud />,
  "Databases & Data Processing": <Storage />,
  "Blockchain & Emerging Tech": <Devices />
};

const AnimatedCard = ({ children, index }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      {children}
    </motion.div>
  );
};

const AnimatedChip = ({ label, index }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3, delay: index * 0.1 }}
    >
      <Chip label={label} className="tech-chip" />
    </motion.div>
  );
};

function TechExpertiseSection() {
  const [expandedCategory, setExpandedCategory] = React.useState(null);

  const categories = [
    {
      category: "Programming Languages",
      techs: ["Python", "JavaScript", "Rust", "Solidity", "TypeScript", "Go", "C++", "Java"]
    },
    {
      category: "Web & Mobile Development",
      techs: ["ReactJS", "MEAN Stack", "React Native", "GraphQL", "RESTful APIs", "Vue.js", "Angular", "Flutter", "Next.js"]
    },
    {
      category: "AI & Machine Learning",
      techs: ["TensorFlow", "PyTorch", "Pandas", "NumPy", "Natural Language Processing", "Computer Vision", "Scikit-learn", "Keras", "OpenCV"]
    },
    {
      category: "Cloud & DevOps",
      techs: ["Azure", "AWS", "Docker", "Kubernetes", "CI/CD", "DevOps", "Google Cloud Platform", "Terraform", "Jenkins", "Ansible"]
    },
    {
      category: "Databases & Data Processing",
      techs: ["PostgreSQL", "MongoDB", "ElasticSearch", "Data Warehousing", "Data Visualization", "Redis", "Apache Kafka", "Apache Spark", "BigQuery"]
    },
    {
      category: "Blockchain & Emerging Tech",
      techs: ["Blockchain Development", "Virtual Reality (VR)", "Metamask", "Augmented Reality (AR)", "IoT", "5G", "Edge Computing", "Quantum Computing"]
    }
  ];

  return (
    <Box className="tech-expertise-section" mt={0} py={6} >
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h3" gutterBottom align="center" className="section-title">
            Our Technological Expertise
          </Typography>
          <Typography variant="h6" align="center" className="section-subtitle" gutterBottom>
         
          </Typography>
        </motion.div>
        <Grid container spacing={4} mt={0}>
          {categories.map((category, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <AnimatedCard index={index}>
                <Paper elevation={3} className="tech-category-card">
                  <Box className="category-header">
                    <IconButton className="category-icon">
                      {categoryIcons[category.category]}
                    </IconButton>
                    <Typography variant="h6" className="category-title">
                      {category.category}
                    </Typography>
                  </Box>
                  <Box className="tech-chips-container">
                    <AnimatePresence>
                      {(expandedCategory === index ? category.techs : category.techs.slice(0, 5)).map((tech, techIndex) => (
                        <AnimatedChip key={techIndex} label={tech} index={techIndex} />
                      ))}
                    </AnimatePresence>
                  </Box>
                  {category.techs.length > 5 && (
                    <motion.div
                      className="expand-button"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => setExpandedCategory(expandedCategory === index ? null : index)}
                    >
                      {expandedCategory === index ? 'Show Less' : 'Show More'}
                    </motion.div>
                  )}
                </Paper>
              </AnimatedCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default TechExpertiseSection;