import React from 'react';
import IndustryTemplate from './IndustryTemplate';
import { Container, Typography, Box, Chip } from '@mui/material';

function Blockchain() {
  const title = "Blockchain Technology Solutions";
  const description = "ARQNXS leverages blockchain technology to provide secure, transparent, and efficient solutions across various industries, from finance to supply chain management.";
  const solutions = [
    { 
      title: "Smart Contracts", 
      description: "Develop and implement secure smart contracts for automated, trustless transactions.",
      useCases: [
        "Automated insurance claim processing",
        "Self-executing financial derivatives",
        "Decentralized autonomous organizations (DAOs)"
      ],
      technologies: ["Solidity", "Ethereum", "Hyperledger Fabric"]
    },
    { 
      title: "Decentralized Applications (DApps)", 
      description: "Create robust decentralized applications for various use cases.",
      useCases: [
        "Decentralized finance (DeFi) platforms",
        "Blockchain-based voting systems",
        "Decentralized marketplaces"
      ],
      technologies: ["Web3.js", "IPFS", "Truffle"]
    },
    { 
      title: "Blockchain Integration", 
      description: "Seamlessly integrate blockchain technology into existing systems for enhanced security and transparency.",
      useCases: [
        "Supply chain traceability",
        "Digital identity verification",
        "Secure data sharing in healthcare"
      ],
      technologies: ["Chainlink", "Polkadot", "Quorum"]
    },
  ];

  const benefits = [
    "Enhanced security and transparency",
    "Reduced operational costs",
    "Improved traceability and auditability",
    "Faster and more efficient transactions",
    "Increased trust in digital ecosystems"
  ];

  return (
    <IndustryTemplate title={title} description={description} solutions={solutions}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>Key Benefits</Typography>
        <Box display="flex" flexWrap="wrap" gap={1} mb={4}>
          {benefits.map((benefit, index) => (
            <Chip key={index} label={benefit} color="primary" />
          ))}
        </Box>
        <Typography variant="h4" gutterBottom>Our Expertise</Typography>
        <Typography variant="body1" paragraph>
          ARQNXS has a team of blockchain experts with extensive experience in developing and implementing blockchain solutions across various industries. We stay at the forefront of blockchain technology, continuously exploring new protocols and consensus mechanisms to deliver the most efficient and secure solutions for our clients.
        </Typography>
      </Container>
    </IndustryTemplate>
  );
}

export default Blockchain;