// src/components/NewsArticle.js
import React, { useState, useEffect } from 'react';
import { useParams, Navigate, Link } from 'react-router-dom';
import { Container, Typography, CircularProgress, Button } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { newsItems } from '../pages/LatestNews';
import LatestNewsTemplate from './LatestNewsTemplate';

function NewsArticle() {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Simulate an API call
    setTimeout(() => {
      const foundArticle = newsItems.find(item => item.id === parseInt(id));
      if (foundArticle) {
        setArticle(foundArticle);
      } else {
        setError('Article not found');
      }
      setLoading(false);
    }, 500); // 500ms delay to simulate network request
  }, [id]);

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', py: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', py: 4 }}>
        <Typography variant="h4" gutterBottom>
          {error}
        </Typography>
        <Button component={Link} to="/news" startIcon={<ArrowBack />}>
          Back to News
        </Button>
      </Container>
    );
  }

  if (!article) {
    return <Navigate to="/news" replace />;
  }

  return (
    <>
      <Container maxWidth="md" sx={{ my: 2 }}>
        <Button component={Link} to="/news" startIcon={<ArrowBack />}>
          Back to News
        </Button>
      </Container>
      <LatestNewsTemplate {...article} />
    </>
  );
}

export default NewsArticle;