import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import herovideo from '../pages/icons/dashboardhero.mp4'
import './HeroSection.css'

const HeroSection = () => {
  return (
    <section className="hero" style={{
      position: 'relative',
      overflow: 'hidden',
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <video
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: -1,
        }}
      >
        <source src={herovideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust opacity as needed
      }} />

      <Container maxWidth="lg" style={{ position: 'relative', zIndex: 1 }}>
        <Typography 
            variant="h1" 
            className="hero-title animate-fade-in-up" 
            gutterBottom 
            sx={{ 
            color: 'white',
            animation: 'fadeInUp 1s ease-out forwards',
            opacity: 0,
            }}
        >
            Transforming Industries with AI and Data Science
        </Typography>
        <Typography 
            variant="h5" 
            paragraph 
            className="hero-subtitle animate-fade-in-up delay-1" 
            sx={{ 
            color: 'white',
            animation: 'fadeInUp 1s ease-out forwards',
            opacity: 0,
            animationDelay: '0.5s',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'

            }}
        >
            ARQNXS delivers cutting-edge solutions in AI, FinTech, and Research & Development, addressing unique business challenges across diverse industries.
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="/contact"
            size="medium"
            className="cta-button"
            style={{ margin: '0 10px' }}
          >
            Start Your Transformation
          </Button>
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="/servicecalculator"
            size="medium"
            className="cta-button"
            style={{ margin: '0 10px' }}
          >
            Calculate Your Savings
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default HeroSection;